const CheckmarkIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7.5" fill="white" stroke="#00AB55" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5825 5.3801L6.54242 11.3327L3.5 8.38246L4.08012 7.78421L6.5377 10.1673L11.9976 4.78656L12.5825 5.3801Z"
      fill="#00AB55"
    />
  </svg>
)

export default CheckmarkIcon
