import { useState } from 'react'

import { SVGIcon } from 'components/Icon'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { onEnterKeyPress } from 'utils/keyboard'

const FAQ = ({ question, answer }: { question: string; answer: string }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className="mb-6 flex flex-col border-b-[1px] border-rb-gray-100 pb-6">
      <div
        className="flex grow items-center justify-between hover:cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
        role="button"
        tabIndex={0}
        onKeyUp={onEnterKeyPress(() => setIsExpanded(!isExpanded))}
      >
        <RfParagraphMedium>{question}</RfParagraphMedium>
        <SVGIcon
          name={`thin-chevron-${isExpanded ? 'up' : 'down'}`}
          height="8"
          width="16"
          fill="#0f0f0f"
          className="ml-4 min-w-[1rem]"
        />
      </div>
      {isExpanded && (
        <RfParagraphSmall color={ColorOptions.gray} className="mt-6 [&_p]:-mb-0">
          <span dangerouslySetInnerHTML={{ __html: answer || '' }} />
        </RfParagraphSmall>
      )}
    </div>
  )
}

export default FAQ
