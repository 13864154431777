const CheckmarkBorderlessIcon = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.4952 14.2806L21.2545 49.9961L3 32.2947L6.48073 28.7052L21.2262 43.0039L53.9855 10.7194L57.4952 14.2806Z"
      fill="#00AB55"
    />
  </svg>
)

export default CheckmarkBorderlessIcon
