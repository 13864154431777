import { ToastContainer, toast } from 'react-toastify'

import { useExternalStylesheet } from 'hooks/useExternalStylesheet'

export const displayToast = toast

export function Toast() {
  useExternalStylesheet(
    'https://cdn.jsdelivr.net/npm/react-toastify@8.1.0/dist/ReactToastify.min.css'
  )

  return (
    <ToastContainer
      hideProgressBar
      bodyClassName="font-sans text-normal text-xl text-rb-gray-500"
    />
  )
}
