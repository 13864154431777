const WorksheetIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.3125 9.25H14.75V50.75H45.3125V9.25ZM10.5 5V55H49.5625V5H10.5Z"
      fill="#4d4d4d"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 21.1875H20V17.1875H40V21.1875Z"
      fill="#4d4d4d"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 29.4375H20V25.4375H40V29.4375Z"
      fill="#4d4d4d"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 37.75H20V33.75H30V37.75Z"
      fill="#4d4d4d"
    />
  </svg>
)

export default WorksheetIcon
