import Pluralize from 'react-pluralize'
import { twMerge } from 'tailwind-merge'

import { HostImage, MultipleHostImages, SingleHostImage } from 'components/HostImages'
import RfHeader1Bold from 'components/typography/RfHeader/RfHeader1Bold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { ProgramSelectorProgramPartsFragment, Season } from 'gql'

import { formatCohortHostNames } from 'utils/cohortUtils'

const Header = ({
  program,
  season
}: {
  program: ProgramSelectorProgramPartsFragment
  season: Season
}) => {
  const cohort = program.cohort
  const cohortHosts = cohort?.hosts || []
  const primaryHost = cohortHosts[0]

  return (
    <div>
      {cohortHosts.length > 1 ? (
        <div className="absolute flex h-[257px] w-full bg-program-info-modal p-10 md:h-[unset]">
          <MultipleHostImages
            containerClassName="ml-auto hidden md:block"
            imgClassName="w-[111px] h-[111px]"
            hosts={cohortHosts.map((cohortHost) => cohortHost as HostImage)}
          />
        </div>
      ) : (
        <SingleHostImage
          containerClassName="flex absolute top-0 min-h-[216px] w-full bg-program-info-modal xs:h-[257px] overflow-hidden"
          imgClassName="ml-auto hidden md:flex h-[135px] xs:h-full"
          host={primaryHost as HostImage}
        />
      )}
      <div className="relative px-6 pt-12 xs:px-12">
        <RfParagraphSmall>{season.prettyNameSeasonFirst} Course</RfParagraphSmall>
        <RfHeader1Bold
          className={twMerge(
            'w-full pt-6 sm:w-2/3',
            cohortHosts.length > 1 ? 'md:w-[70%]' : 'md:w-3/4'
          )}
        >
          {program.name}
        </RfHeader1Bold>
        <RfParagraphSmall>
          Hosted by {formatCohortHostNames(cohortHosts)}
          {!!cohort?.weekCount && (
            <>
              &nbsp;• <Pluralize singular="week" count={cohort?.weekCount} />
            </>
          )}
        </RfParagraphSmall>
      </div>
    </div>
  )
}

export default Header
