import { useEffect } from 'react'

export const useExternalStylesheet = (url: string) => {
  const w: Window & {
    __externalStylesheets?: string[]
  } = window

  useEffect(() => {
    if (!w.__externalStylesheets) {
      w.__externalStylesheets = []
    }

    if (w.__externalStylesheets.includes(url)) {
      return
    }

    w.__externalStylesheets.push(url)

    document.head.appendChild(
      Object.assign(document.createElement('link'), {
        rel: 'stylesheet',
        href: url
      })
    )
  }, [url, w])

  return null
}
