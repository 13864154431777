export const MAIN_DASHBOARD = 'main_dashboard'
export const EVENTS_INDEX = 'events_index'
export const EVENT_ATTENDEES = 'event_attendees'
export const EVENT_RECORDING = 'event_recording'
export const EVENT_DETAILS = 'event_details'
export const PROGRAMS_INDEX = 'programs_index'
export const PROGRAM_DASHBOARD = 'program_dashboard'
export const CONCEPTS_INDEX = 'concepts_index'
export const PROJECTS_INDEX = 'projects_index'
const POSTS_INDEX = 'posts_index'
const MEMBERS_INDEX = 'members_index'
const ACCOUNT_OVERVIEW = 'account'
const BILLING_PAGE = 'billing'
const TEAM_MEMBERS = 'team_members'
const TEAM_SETTINGS = 'team_settings'
const TEAM_ENGAGEMENT_SUMMARY = 'team_engagement_summary'
const TEAM_ENGAGEMENT_TEAM = 'team_engagement_team'
const COHORT_HISTORY_PAGE = 'cohort_history'

export function getPageFromPath(): string {
  const path: string = window.location.pathname.replace(/\/$/, '')

  if (path === '') {
    return MAIN_DASHBOARD
  } else if (path === '/events') {
    return EVENTS_INDEX
  } else if (path === '/programs') {
    return PROGRAMS_INDEX
  } else if (path.indexOf('/programs/') > -1) {
    return PROGRAM_DASHBOARD
  } else if (path.indexOf('/events/') > -1 && path.indexOf('/attendees') > -1) {
    return EVENT_ATTENDEES
  } else if (path.indexOf('/events/') > -1 && path.indexOf('/summary') > -1) {
    return EVENT_RECORDING
  } else if (path.indexOf('/events/') > -1) {
    return EVENT_DETAILS
  } else if (path === '/concepts') {
    return CONCEPTS_INDEX
  } else if (path === '/projects') {
    return PROJECTS_INDEX
  } else if (path === '/posts') {
    return POSTS_INDEX
  } else if (path === '/directory' || path.startsWith('/directory?')) {
    return MEMBERS_INDEX
  } else if (path === '/account') {
    return ACCOUNT_OVERVIEW
  } else if (path === '/billing') {
    return BILLING_PAGE
  } else if (path === '/my_team/members') {
    return TEAM_MEMBERS
  } else if (path === '/my_team/general') {
    return TEAM_SETTINGS
  } else if (path === '/team-engagement/summary') {
    return TEAM_ENGAGEMENT_SUMMARY
  } else if (path === '/team-engagement/team') {
    return TEAM_ENGAGEMENT_TEAM
  } else if (path === '/cohort-history') {
    return COHORT_HISTORY_PAGE
  }

  return path
}

export function getEventIdFromPath(): string {
  const path: string = window.location.pathname
  if (path.indexOf('/events') < 0) return path

  const result: RegExpMatchArray | null = path.match(/\d+/)
  if (result) {
    return result[0]
  }

  return path
}
