const FieldError = ({ name, errors }: { name: string; errors: any }) => {
  const fieldError = errors && errors[name] ? errors[name].message : null

  if (!fieldError) return null

  return (
    <div
      className="absolute text-left text-xs text-rb-destructive-100"
      data-test={`form-field-error-${name}`}
    >
      {fieldError}
    </div>
  )
}

export default FieldError
