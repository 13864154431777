import React, { ReactElement, ReactNode } from 'react'
import { PopoverAlign, PopoverPosition } from 'react-tiny-popover'
import { twMerge } from 'tailwind-merge'

import Dropdown from 'components/dropdowns/Dropdown'
import { ReactComponent as IconThreeDotsCircleWhite } from 'components/dropdowns/Dropdown/three-dots-circle-white.svg'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { isMobileDevice } from 'utils/device.utils'

interface DropdownContextMenuProps {
  children: ReactNode
  triggerElement?: ReactElement
  className?: string
  dataTest?: string
  triggerClassName?: string
  positions?: PopoverPosition[]
  reposition?: boolean
  boundaryInset?: number
  dismissOnClick?: boolean
  align?: PopoverAlign
  onToggleDropdown?: (toOpen: boolean) => void
  preventFurtherHover?: boolean
}

const DropdownContextMenu = ({
  children,
  triggerElement = (
    <IconThreeDotsCircleWhite
      data-test="icon-three-dots-circle-white"
      width="32"
      height="32"
      aria-label="SubMenu"
    />
  ),
  className = '',
  dataTest = '',
  triggerClassName = '',
  positions = ['top'],
  reposition,
  boundaryInset,
  dismissOnClick = false,
  align = isMobileDevice() ? 'end' : 'start',
  onToggleDropdown = () => {},
  preventFurtherHover = false
}: DropdownContextMenuProps) => (
  <Dropdown
    triggerElement={triggerElement}
    triggerClassName={triggerClassName}
    positions={positions}
    buttonDataTest={dataTest}
    reposition={reposition}
    boundaryInset={boundaryInset}
    dismissOnClick={dismissOnClick}
    align={align}
    onToggleDropdown={onToggleDropdown}
  >
    <div
      className={twMerge('border border-rb-gray-250 bg-white p-2', className)}
      onMouseOver={(e) => preventFurtherHover && e.stopPropagation()}
      onFocus={(e) => preventFurtherHover && e.stopPropagation()}
    >
      {children}
    </div>
  </Dropdown>
)

interface DropdownItemProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onHover?: () => void
  text: string
  children?: ReactNode
  icon?: ReactNode
  className?: string
  textClassName?: string
  iconContainerClassName?: string
  disabled?: boolean
}

const DropdownItem = ({
  onClick,
  icon,
  text,
  children,
  className = '',
  textClassName = '',
  iconContainerClassName = '',
  onHover,
  disabled = false
}: DropdownItemProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) return

    onClick?.(e)
  }

  return (
    <button
      data-test={`dropdown-context-menu-item-${text.replaceAll(' ', '')}`}
      data-testid={`dropdown-context-menu-item-${text.replaceAll(' ', '')}`}
      className={twMerge(
        'flex h-10 w-[225px] items-center bg-white px-2 py-1 hover:bg-rb-gray-50',
        className,
        `${
          disabled &&
          'cursor-default border-rb-gray-50 bg-rb-gray-50 hover:border-rb-gray-50 hover:bg-rb-gray-50'
        }`
      )}
      onMouseOver={onHover}
      onFocus={onHover}
      onClick={handleClick}
      disabled={disabled}
    >
      {icon && <div className={twMerge('pr-2', iconContainerClassName)}>{icon}</div>}
      <RfParagraphMedium>
        <span
          className={
            disabled
              ? 'color-input-text text-rb-gray-200 hover:text-rb-gray-200'
              : textClassName
          }
        >
          {children || text}
        </span>
      </RfParagraphMedium>
    </button>
  )
}

DropdownContextMenu.DropdownItem = DropdownItem

export default DropdownContextMenu
