const DefaultAvatarIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="125"
    height="125"
    viewBox="0 0 125 125"
    className={className}
  >
    <g id="member-avatar" transform="translate(-483 -163)">
      <g
        id="Ellipse_59"
        data-name="Ellipse 59"
        fill="none"
        stroke="#0f0f0f"
        strokeWidth="1.5px"
        transform="translate(483 163)"
      >
        <circle stroke="none" cx="62.5" cy="62.5" r="62.5" />
        <circle fill="none" cx="62.5" cy="62.5" r="61.75" />
      </g>
      <g
        id="Icon_User_Outline"
        data-name="Icon/User/Outline"
        transform="translate(511.125 189.25)"
      >
        <g
          id="Ellipse_16"
          data-name="Ellipse 16"
          fill="none"
          stroke="#0f0f0f"
          strokeWidth="1.5px"
          transform="translate(14.375)"
        >
          <circle stroke="none" cx="19.56" cy="19.56" r="19.56" />
          <circle fill="none" cx="19.56" cy="19.56" r="18.81" />
        </g>
        <path
          id="Path_56"
          data-name="Path 56"
          fill="none"
          stroke="#0f0f0f"
          strokeWidth="1.5px"
          d="M-8626.15-6684.814s8.233-29.643,33.732-29.643,32.355,29.643,32.355,29.643"
          transform="translate(8626.15 6752.569)"
        />
      </g>
    </g>
  </svg>
)

export default DefaultAvatarIcon
