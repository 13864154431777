import {
  ApolloClient,
  ApolloLink,
  FieldFunctionOptions,
  HttpLink,
  InMemoryCache,
  ServerError
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import fetch from 'cross-fetch'

import {
  QueryAttendeesFeedArgs,
  QueryConceptsArgs,
  QueryEventsPastFeedArgs,
  QueryProjectsArgs,
  QueryUserEventsPastFeedArgs,
  QueryUsersFeedArgs
} from 'gql'
import possibleTypes from 'gql/possibleTypes'

import { IFlashMessage, NoticeType } from 'hooks/useFlashMessage'

import notifyError from 'utils/errorNotifier'
import getCsrfToken from 'utils/getCsrfToken'

// possible codes for a schema mismatch
// https://github.com/rmosolgo/graphql-ruby/search?l=Ruby&q=%22def+code%22
const possibleSchemaMismatchErrorCodes = [
  'argumentLiteralsIncompatible',
  'argumentNotAccepted',
  'cannotSpreadFragment',
  'directiveCannotBeApplied',
  'fieldConflict',
  'missingRequiredArguments',
  'missingRequiredInputObjectAttribute',
  'selectionMismatch',
  'undefinedDirective',
  'undefinedField',
  'undefinedType',
  'variableMismatch'
]

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (networkError) {
    console.warn(`[Network error]: ${networkError}`)

    // pluck errors out of the result and notify for error handling
    // typing says result is an object but if we use batch http link it's actually an array
    const serverError = networkError as ServerError
    const errors =
      serverError.result?.errors ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      serverError.result?.map?.((result: Record<string, any>) => result.errors)

    notifyError(networkError, {}, (event) => {
      event.addMetadata('gqlOperationName', { OperationName: operation.operationName })
      event.addMetadata('gqlQuery', operation.query)
      event.addMetadata('gqlNetworkErrors', errors)
      // https://docs.bugsnag.com/platforms/javascript/react/customizing-error-reports/#groupinghash
      event.groupingHash = operation.operationName
    })
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((graphqlError) => {
      // This is not supposed to be a string, but it can be
      if (typeof graphqlError === 'string') {
        console.warn(`[GraphQL error]: Message: ${graphqlError}`)
      } else {
        // Default apollo handling
        const { message, locations, path, extensions } = graphqlError
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}, Extensions: ${JSON.stringify(extensions)}`
        )

        // Add flash message
        if (extensions?.flash_message) {
          const flashMessage: IFlashMessage = {
            message: extensions.flash_message as string,
            type: extensions.flash_message_type as NoticeType
          }
          window.sessionStorage.setItem('rf-flash-message', JSON.stringify(flashMessage))
        }

        // Redirect
        if (extensions?.redirect) {
          window.location.assign(extensions.redirect as string)
        }

        if (extensions?.code === 'MAINTENANCE') {
          if (window.location.pathname !== '/maintenance') {
            window.location.assign('/maintenance')
          }
        }

        if (
          possibleSchemaMismatchErrorCodes.includes(
            graphqlError.extensions?.code as string
          )
        ) {
          const url = new URL(window.location.href)
          const reloadCount = Number(url.searchParams.get('reloadCount') || 0)
          if (reloadCount < 1) {
            url.searchParams.set('reloadCount', `${reloadCount + 1}`)
            // append a unique query param to ensure browser cache is not used
            url.searchParams.set('reloadTime', Date.now().toString())
            window.location.href = url.toString()
          } else {
            notifyError(
              new Error('Found schema mismatch requiring more than one reload'),
              {},
              (event) => {
                event.addMetadata('reloadCount', { reloadCount: reloadCount })
                event.addMetadata('gqlOperationName', {
                  OperationName: operation.operationName
                })
                event.addMetadata('gqlQuery', operation.query)
                event.addMetadata('gqlError', graphqlError)
              }
            )
          }
        }
      }
    })

    const message = graphQLErrors.map((graphQLError) => graphQLError.message).join('\n')

    notifyError(message, {}, (event) => {
      event.addMetadata('gqlOperationName', { OperationName: operation.operationName })
      event.addMetadata('gqlQuery', operation.query)
      event.addMetadata('gqlErrors', graphQLErrors)
      // https://docs.bugsnag.com/platforms/javascript/react/customizing-error-reports/#groupinghash
      event.groupingHash = operation.operationName
    })
  }
})

const commonLinks = [errorLink]

const csrfToken = getCsrfToken()

const publicHttpLink = new HttpLink({
  uri: '/graphql-public',
  credentials: 'same-origin',
  fetch,
  headers: {
    'X-CSRF-Token': csrfToken
  }
})

interface FeedPaginationArgs {
  offset?: number | null
}

interface FeedPaginationOptions {
  listKey: string
}

const mergeFeedPagination =
  <TArgs extends FeedPaginationArgs>({ listKey }: FeedPaginationOptions) =>
  (existing: any, incoming: any, { args, canRead }: FieldFunctionOptions<TArgs>) => {
    const existingList = existing?.[listKey] || []
    const incomingList = incoming?.[listKey] || []

    const merged = existing ? existingList.slice(0) : []

    // if the total count doesn't match that indicates an item was removed/filtered out
    if (existing?.count !== incoming?.count) {
      return { ...existing, ...incoming }
    }

    if (args) {
      const offset = args.offset || 0
      for (let i = 0; i < incomingList.length; ++i) {
        merged[offset + i] = incomingList[i]
      }

      // fill in any gaps with nulls
      for (let i = existingList.length; i <= offset; ++i) {
        if (!canRead(merged[i])) {
          merged[i] = null
        }
      }
    } else {
      merged.push.apply(merged, incomingList)
    }

    return {
      ...existing,
      ...incoming,
      [listKey]: merged
    }
  }

const mergePagination =
  ({ listKey }: FeedPaginationOptions) =>
  (existing: any, incoming: any) => {
    const existingList = existing?.[listKey] || []
    const incomingList = incoming?.[listKey] || []

    const merged = [...existingList, ...incomingList]

    return { ...existing, ...incoming, [listKey]: merged }
  }

const privateHttpLink = new HttpLink({
  uri: '/graphql',
  credentials: 'same-origin',
  fetch,
  headers: {
    'X-CSRF-Token': csrfToken
  }
})

export const privateApolloClient = new ApolloClient({
  link: ApolloLink.from([...commonLinks, privateHttpLink]),
  cache: new InMemoryCache({
    ...possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          cohortPosts: {
            keyArgs: ['slug', 'topicSlug'],
            merge: mergeFeedPagination<QueryEventsPastFeedArgs>({ listKey: 'posts' })
          },
          concepts: {
            keyArgs: ['filters', 'userId'],
            merge: mergeFeedPagination<QueryConceptsArgs>({ listKey: 'content' })
          },
          bookmarksFeed: {
            keyArgs: ['filters', 'sort', 'limit'],
            merge: mergeFeedPagination<QueryConceptsArgs>({ listKey: 'bookmarks' })
          },
          eventsPastFeed: {
            keyArgs: ['filters', 'userId'],
            merge: mergeFeedPagination<QueryEventsPastFeedArgs>({ listKey: 'events' })
          },
          userEventsPastFeed: {
            keyArgs: ['filters'],
            merge: mergeFeedPagination<QueryUserEventsPastFeedArgs>({ listKey: 'events' })
          },
          getUser: { keyArgs: ['email'] },
          getUserHistory: { keyArgs: ['email'] },
          projects: {
            keyArgs: ['filters', 'userId'],
            merge: mergeFeedPagination<QueryProjectsArgs>({ listKey: 'content' })
          },
          validateEmail: { keyArgs: ['email'] },
          usersFeed: {
            keyArgs: ['search', 'cohortSlug', 'userId'],
            merge: mergeFeedPagination<QueryUsersFeedArgs>({ listKey: 'users' })
          },
          attendeesFeed: {
            keyArgs: ['search', 'eventId'],
            merge: mergeFeedPagination<QueryAttendeesFeedArgs>({ listKey: 'attendees' })
          },
          filteredUsers: {
            keyArgs: ['filters'],
            merge: mergePagination({
              listKey: 'members'
            })
          },
          recentChats: {
            keyArgs: ['offset'],
            merge: (existing: any, incoming: any) => {
              const arr1 = existing?.edges || []
              const arr2 = incoming?.edges || []
              const mergedMap = new Map<string, any>()
              const addOrMerge = (obj: any) => {
                if (mergedMap.has(obj.node.extId)) {
                  mergedMap.set(obj.node.extId, {
                    ...mergedMap.get(obj.node.extId),
                    ...obj
                  })
                } else {
                  mergedMap.set(obj.node.extId, obj)
                }
              }
              arr1.forEach(addOrMerge)
              arr2.forEach(addOrMerge)

              const mergedEdges = Array.from(mergedMap.values()).sort((a, b) =>
                b.node.createdAt.localeCompare(a.node.createdAt)
              )

              return { ...existing, ...incoming, edges: mergedEdges }
            }
          },
          cmsProgram: { keyArgs: ['cmsProgramId', 'slug'] },
          bookmarks: { keyArgs: ['cmsSectionId'] }
        }
      },
      Event: {
        fields: {
          attendees: relayStylePagination(['hasAvatar'])
        }
      },
      LessonViewer: {
        merge: true
      },
      UserIs: {
        merge: true
      },
      UserCan: {
        merge: true
      },
      UserCohorts: {
        merge: true
      },
      UserPricing: {
        merge: true
      },
      UserProfile: {
        merge: true
      },
      UserPreference: {
        merge: true
      },
      UserSubscriptions: {
        merge: true
      },
      UserContact: {
        merge: true
      },
      ContentViewer: {
        merge: true
      },
      User: {
        merge: true
      },
      ProductTour: {
        // unique per user, to optimistically update the cache when a user completes a tour
        keyFields: ['__typename'],
        merge: true
      },
      BookmarkFolder: {
        fields: {
          usersSharedWith: {
            merge(_existing, incoming) {
              return incoming
            }
          },
          individualUsersSharedWith: {
            merge(_existing, incoming) {
              return incoming
            }
          }
        }
      }
    }
  })
})

export const publicApolloClient = new ApolloClient({
  link: ApolloLink.from([...commonLinks, publicHttpLink]),
  cache: new InMemoryCache({
    ...possibleTypes
  })
})
