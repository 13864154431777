import RfParagraphLargeBold from 'components/typography/RfParagraph/RfParagraphLargeBold'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { ProgramSelectorProgramPartsFragment } from 'gql'

const ProgramDetails = ({
  program
}: {
  program: ProgramSelectorProgramPartsFragment
}) => {
  return (
    <div className="relative mt-14">
      <RfParagraphLargeBold>{program.synopsis}</RfParagraphLargeBold>
      <RfParagraphMediumBold className="mt-8 mb-4">
        Who&apos;s this for
      </RfParagraphMediumBold>
      <RfParagraphSmall>{program.whoItsFor}</RfParagraphSmall>
      <RfParagraphMediumBold className="mt-8 mb-4">
        By the end, you&apos;ll be able to
      </RfParagraphMediumBold>
      <ul className="mt-0 pl-6">
        {program.byTheEnd?.split(';').map((item: string, i) => (
          <li key={`items-${i}`} className="mb-1">
            <RfParagraphSmall>{item}</RfParagraphSmall>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ProgramDetails
