import { useEffect, useState } from 'react'

export const useAfterDelay = (delayMs?: number) => {
  const [pastDelay, setPastDelay] = useState(!delayMs)

  useEffect(() => {
    if (delayMs) {
      const timeoutId = setTimeout(() => {
        setPastDelay(true)
      }, delayMs)
      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [delayMs])

  return pastDelay
}
