import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface RfHeader2Props {
  children: ReactNode
  className?: string
  id?: string
}

const RfHeader2 = ({ children, className, id }: RfHeader2Props) => (
  <h2 id={id} className={twMerge('rf-h2 mb-0', className)}>
    {children}
  </h2>
)

export default RfHeader2
