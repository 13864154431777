import * as React from 'react'

interface IDescription {
  description: string
  hasError?: boolean
}

const Description = ({ description, hasError }: IDescription) => (
  <div
    className={`text-xs font-semibold text-rb-gray-300 ${
      hasError && 'text-rb-destructive-100'
    }`}
  >
    {description}
  </div>
)

export interface IWithDescription {
  description?: string
  hasError?: boolean
}

const withDescription = <P extends object>(
  Element: React.ComponentType<P>
): React.FC<P & IWithDescription> => {
  const WithDescription = ({ description, ...props }: IWithDescription) => (
    <>
      <Element {...(props as P)} />
      {description && <Description description={description} hasError={props.hasError} />}
    </>
  )
  return WithDescription
}

export default withDescription
