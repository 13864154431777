import React from 'react'
import { twMerge } from 'tailwind-merge'

const LoopIcon = ({ className }: { className?: string }) => (
  <svg
    className={twMerge('h-[12px] w-[12px]', className)}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 0C5.40039 0 4.80078 0.0996095 4.25 0.25L5.15625 1.09375C5.45703 1.04297 5.69922 1 6 1C7.41211 1 8.66016 1.5918 9.5625 2.5H8V3.5H11V0.5H10V1.53125C8.9375 0.578125 7.54883 0 6 0ZM0.5 1V2H1.5625C0.609375 3.05859 0 4.44531 0 6C0 6.55078 0.103515 7.09375 0.203125 7.59375L1.04688 6.75C0.996094 6.5 1 6.25 1 6C1 4.5918 1.58984 3.35938 2.5 2.4375V4H3.5V1H0.5ZM11.75 4.40625L10.9531 5.29688C11.0039 5.54688 11 5.75 11 6C11 7.40625 10.4219 8.65234 9.5 9.5625V8H8.5V11H11.5V10H10.4844C11.4414 8.93359 12 7.53711 12 6C12 5.44922 11.9004 4.90625 11.75 4.40625ZM1 8.5V11.5H2V10.4375C3.0625 11.4023 4.45312 12 6 12C6.5 12 7.00391 11.9473 7.45312 11.7969L6.5 11H6C4.58789 11 3.33984 10.4082 2.4375 9.5H4V8.5H1Z"
      fill="#0F0F0F"
    />
  </svg>
)

export default LoopIcon
