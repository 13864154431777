export type UrlTokensMap = Record<string, string | number | null | undefined | boolean>

export function makeUrl(
  url: string,
  tokens?: UrlTokensMap | null,
  params?: UrlTokensMap | null
): string {
  const baseUrl = tokens ? replaceTokens(url, tokens) : url
  const formattedParams = params ? formatParams(params) : {}
  const queryParam = new URLSearchParams(formattedParams).toString()
  return [baseUrl, queryParam].filter(Boolean).join('?')
}

function replaceTokens(urlTemplate: string, tokens: UrlTokensMap) {
  return Object.entries(tokens).reduce((url, [key, value]) => {
    if (isEmpty(value)) return url

    return replaceAll(url, `:${key}`, value.toString())
  }, urlTemplate)
}

function replaceAll(str: string, needle: string, replace: string) {
  return str.replace(new RegExp(needle, 'g'), replace)
}

function isEmpty(value: unknown): value is undefined | null {
  return value === undefined || value === null
}

function isString(value: unknown): value is string {
  return typeof value === 'string'
}

function isNumber(value: unknown): value is number {
  return typeof value === 'number'
}

function isBoolean(value: unknown): value is number {
  return typeof value === 'boolean'
}

function validateUrlTokensMap(
  tuple: [string, string | number | null | undefined | boolean]
): tuple is [string, string | number | boolean] {
  const [, value] = tuple
  return isString(value) || isNumber(value) || isBoolean(value)
}

function formatParams(params: UrlTokensMap) {
  return Object.fromEntries(
    Object.entries(params)
      .filter(validateUrlTokensMap)
      .map(([key, value]) => [key.toString(), value.toString()])
  )
}
