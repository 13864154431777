import { twMerge } from 'tailwind-merge'

const PlayIconBlack = ({ className }: { className?: string }) => (
  <svg
    className={twMerge(className, 'h-[12px] w-[12px]')}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.175 6C10.175 8.29198 8.31701 10.15 6.02502 10.15C3.73304 10.15 1.87502 8.29198 1.87502 6C1.87502 3.70802 3.73304 1.85 6.02502 1.85C8.31701 1.85 10.175 3.70802 10.175 6ZM11.025 6C11.025 8.76142 8.78645 11 6.02502 11C3.2636 11 1.02502 8.76142 1.02502 6C1.02502 3.23858 3.2636 1 6.02502 1C8.78645 1 11.025 3.23858 11.025 6ZM5.00002 7.73205L8.00002 6L5.00002 4.26795V7.73205Z"
      fill="#0F0F0F"
    />
  </svg>
)

export default PlayIconBlack
