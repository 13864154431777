export const partition = <T>(arr: T[], criteria: (a: T) => boolean) => {
  return [arr.filter((item) => criteria(item)), arr.filter((item) => !criteria(item))]
}

export const dedupeObjectArrayByKey = (arr: any[], key: string) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}

// Useful for URL array params, which in Rails have an "empty" value of [""] or [] (as opposed to just "")
export const isEmptyArrayParameter = (value: any) =>
  Array.isArray(value) && (value.length === 0 || (value.length === 1 && value[0] === ''))

// Pick random item from array
export const pickRandom = <T>(arr: T[]): T | null => {
  if (!arr.length) {
    return null
  }

  return arr[Math.floor(Math.random() * arr.length)]
}

// return a random sample of n number of elements from an array
export const getRandom = <T>(arr: T[], n: number): T[] => {
  const result = new Array(n)
  let len = arr.length
  const taken = new Array(len)

  if (len < n) {
    n = len
  }

  while (n--) {
    var x = Math.floor(Math.random() * len)
    result[n] = arr[x in taken ? taken[x] : x]
    taken[x] = --len in taken ? taken[len] : len
  }
  return result
}

// behaves like [].join but allows specifying the last delimiter
export const prettyJoin = (
  arr: any[],
  delimiter: string,
  lastDelimiter?: string
): string => {
  if (!arr.length) {
    return ''
  }

  if (!lastDelimiter || arr.length === 1) {
    return arr.join(delimiter)
  }

  return `${arr.slice(0, -1).join(delimiter)}${lastDelimiter}${arr.slice(-1)}`
}

// To be used to determine a join delimiter when you cannot use a string function like join (because of things lke type coerscion of the array elements)
// See test for an example of how it is used
export const prettyDelimiter = (
  arrLength: number,
  currentIndex: number,
  delimiter: string,
  lastDelimiter?: string
) => {
  const paddedDelimiter = `${delimiter} `
  const paddedLastDelimiter = lastDelimiter ? ` ${lastDelimiter} ` : ''

  if (arrLength <= 1 || currentIndex === arrLength - 1) {
    return ''
  }

  if (currentIndex === arrLength - 2) {
    return paddedLastDelimiter || paddedDelimiter
  }

  return paddedDelimiter
}
