import { twMerge } from 'tailwind-merge'

const CloseIcon = ({ className }: { className?: string }) => (
  <svg
    className={twMerge('stroke-current', className)}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 6L6 18" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6L18 18" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default CloseIcon
