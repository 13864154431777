import * as React from 'react'
import { twMerge } from 'tailwind-merge'

export default function ColorCircleCheckIcon(
  props: React.SVGAttributes<SVGElement>
): React.ReactElement {
  const { className, width = '20', height = '20', ...rest } = props

  return (
    <svg
      className={twMerge('text-rb-green-100', className)}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...rest}
    >
      <circle cx="12" cy="12" r="12" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6653 7.80819L9.00117 17.3323L4.1333 12.612L5.0615 11.6548L8.99363 15.4677L17.7294 6.85852L18.6653 7.80819Z"
        fill="white"
      />
    </svg>
  )
}
