import { ApolloError, useApolloClient } from '@apollo/client'
import { publicApolloClient } from 'apolloClient'
import { ReactNode, createContext, useContext } from 'react'

import { CurrentUserPartsFragment, useCurrentUserQuery } from 'gql'

export interface UserContextType {
  isLoggedIn: boolean
  currentUser: CurrentUserPartsFragment | null | undefined
  currentUserLoading: boolean
  currentUserError: ApolloError | undefined
  currentUserUpdating: boolean
  refetchCurrentUser: () => void
}

export const UserContext = createContext<UserContextType>({
  isLoggedIn: false,
  currentUser: null,
  currentUserLoading: true,
  currentUserError: undefined,
  currentUserUpdating: false,
  refetchCurrentUser: () => {}
})

interface UserContextProviderProps {
  children: ReactNode
  isLoggedIn: boolean
}

export function UserContextProvider({ children, isLoggedIn }: UserContextProviderProps) {
  const apolloclient = useApolloClient()
  const { data, loading, error, refetch } = useCurrentUserQuery({
    skip: apolloclient === publicApolloClient,
    notifyOnNetworkStatusChange: true // https://github.com/apollographql/apollo-client/issues/1186
  })

  return (
    <UserContext.Provider
      value={{
        isLoggedIn, // alows access to logged in status without/before fetching currentUser
        currentUser: data?.currentUser,
        currentUserLoading: loading,
        currentUserError: error,
        currentUserUpdating: loading && !!data?.currentUser,
        refetchCurrentUser: refetch
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export function useCurrentUser() {
  return useContext(UserContext)
}

export function useAssertCurrentUser() {
  const { currentUser } = useContext(UserContext)
  if (!currentUser) {
    throw new Error('Expected currentUser to be loaded.')
  }
  return currentUser
}
