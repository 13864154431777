const PlayCircleBorderIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    data-testid="play-circle-border-icon"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.3958 25C42.3958 34.5499 34.654 42.2917 25.1041 42.2917C15.5542 42.2917 7.81242 34.5499 7.81242 25C7.81242 15.4501 15.5542 7.70833 25.1041 7.70833C34.654 7.70833 42.3958 15.4501 42.3958 25ZM45.9374 25C45.9374 36.5059 36.61 45.8333 25.1041 45.8333C13.5982 45.8333 4.27075 36.5059 4.27075 25C4.27075 13.4941 13.5982 4.16666 25.1041 4.16666C36.61 4.16666 45.9374 13.4941 45.9374 25ZM20.8333 32.2169L33.3333 25L20.8333 17.7831V32.2169Z"
    />
  </svg>
)

export default PlayCircleBorderIcon
