import { MemberBadgeVariant } from 'components/BadgeableAvatar'

import { Maybe } from 'gql'

import { MemberKind } from '../typings/legacy/member'

export interface GetMemberBadgeInput {
  hasBadge?: Maybe<boolean>
  kind?: Maybe<MemberKind>
}

interface GetMemberRoleAndCompanyInput {
  role?: Maybe<string>
  companyName?: Maybe<string>
}

export const getMemberRoleAndCompany = ({
  role,
  companyName
}: GetMemberRoleAndCompanyInput) => {
  const company = companyName || ''
  const currentRole = role || ''

  // return what information is available
  if (role && company) return `${currentRole} @ ${company}`
  else if (company) return `${company}`
  else if (role) return `${currentRole}`
  else return null
}

export const getMemberBadge = ({ hasBadge, kind }: GetMemberBadgeInput) => {
  let badge: {
    color: MemberBadgeVariant
    class: string
    showBadge: boolean
  } = {
    color: 'none',
    class: '',
    showBadge: false
  }

  if (hasBadge) {
    badge = {
      color: 'black',
      class: 'bg-rb-gray-500 text-rb-white',
      showBadge: true
    }
  }
  if (kind === 'collective') {
    badge = {
      color: 'yellow-green',
      class: 'bg-rb-green-100 text-rb-black',
      showBadge: true
    }
  }

  return badge
}
