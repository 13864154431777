const EyeglassIcon = ({ className }: { className?: string }) => (
  <svg
    className={`fill-current${className ? ` ${className}` : ''}`}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.69106 14.0319C11.788 14.0319 14.2985 11.5214 14.2985 8.42446C14.2985 5.32758 11.788 2.81706 8.69106 2.81706C5.59418 2.81706 3.08366 5.32758 3.08366 8.42446C3.08366 11.5214 5.59418 14.0319 8.69106 14.0319ZM8.69106 15.4485C12.5704 15.4485 15.7151 12.3038 15.7151 8.42446C15.7151 4.54518 12.5704 1.40039 8.69106 1.40039C4.81178 1.40039 1.66699 4.54518 1.66699 8.42446C1.66699 12.3038 4.81178 15.4485 8.69106 15.4485Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2435 18.3002L12.921 13.6896L13.8952 12.6528L18.2177 17.2635L17.2435 18.3002Z"
    />
  </svg>
)

export default EyeglassIcon
