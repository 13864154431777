import * as React from 'react'

import withContainer, { IWithContainer } from 'components/forms/enhancers/withContainer'
import withDescription, {
  IWithDescription
} from 'components/forms/enhancers/withDescription'
import withLabel, { ILabel } from 'components/forms/enhancers/withLabel'
import withValidation from 'components/forms/enhancers/withValidation'
import { IFormElementBase } from 'components/forms/formTypes'

const withDefaultEnhancers = <P extends object>(
  Element: React.ComponentType<P>
): React.FC<P & IWithContainer & IWithDescription & ILabel & IFormElementBase> => {
  const ElementWithEnhancers = withValidation(
    withLabel(withDescription(withContainer(Element)))
  )

  return ElementWithEnhancers
}

export default withDefaultEnhancers
