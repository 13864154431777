import clsx from 'clsx'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { CloseIcon } from 'components/icons'

import { ANIMATION_DURATION } from 'constants/animations'

import useOnClickOutside from 'hooks/useOnClickOutside'

import { onEnterKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'

interface UseAutoCloseParams {
  autoCloseInSeconds?: number
  handleClose: () => void
  isOpen: boolean
}

const useAutoClose = ({
  autoCloseInSeconds,
  handleClose,
  isOpen
}: UseAutoCloseParams) => {
  const [isFadingOut, setIsFadingOut] = useState(false)

  useEffect(() => {
    if (!autoCloseInSeconds || !isOpen) return

    const autoCloseTimeout = setTimeout(() => {
      setIsFadingOut(true)

      // Call close callback after animation ends
      setTimeout(() => {
        handleClose()
      }, ANIMATION_DURATION)
    }, autoCloseInSeconds * 1000)

    return () => {
      setIsFadingOut(false)
      clearTimeout(autoCloseTimeout)
    }
  }, [autoCloseInSeconds, handleClose, isOpen])
  return isFadingOut
}

export type ModalProps = {
  modalId?: string
  isOpen: boolean
  fullHeight?: boolean
  fixedHeight?: boolean
  handleClose: () => void
  fullWidth?: boolean
  scrollContent?: boolean
  header?: boolean
  className?: string | null
  style?: any
  containerClass?: string
  children: any
  dataTest?: string
  autoCloseInSeconds?: number
  closeOnEscape?: boolean
  closeOnOutsideClick?: boolean
  animateOnOpen?: boolean
}

export const CloseButton = ({ handleClose }: { handleClose: () => void }) => {
  return (
    <div
      tabIndex={0}
      role="button"
      aria-label="close-modal-button"
      data-test="close-button"
      data-testid="close-button"
      data-cy="close-button"
      className="hover:bg-default ml-auto cursor-pointer px-3 py-3 text-xl md:px-4 md:py-4"
      onClick={handleClose}
      onKeyUp={onEnterKeyPress(handleClose)}
    >
      <CloseIcon className="h-6 w-6" />
    </div>
  )
}

export const Modal = ({
  modalId,
  isOpen,
  fullHeight = true,
  fixedHeight = false,
  handleClose,
  fullWidth = false,
  scrollContent = true,
  header = true,
  containerClass,
  className,
  style,
  children,
  dataTest,
  autoCloseInSeconds,
  closeOnEscape = false,
  closeOnOutsideClick = true,
  animateOnOpen = true
}: ModalProps) => {
  const isFadingOut = useAutoClose({ autoCloseInSeconds, handleClose, isOpen })

  useEffect(() => {
    if (isOpen && closeOnEscape) {
      const close = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          handleClose()
        }
      }
      window.addEventListener('keyup', close)
      return () => window.removeEventListener('keyup', close)
    }
  }, [closeOnEscape, handleClose, isOpen])

  const ref: any = useRef()
  useOnClickOutside(ref, () => handleClose(), { enabled: closeOnOutsideClick && isOpen })

  if (!isOpen) return null

  const boxClasses = cn(
    'bg-white w-full box-border mt-auto mb-auto',
    animateOnOpen && 'animate-slideDown animate-fadeIn',
    !fullWidth && 'max-w-full sm:w-5/6 2xl:w-3/6',
    scrollContent ? 'flex flex-col' : 'overflow-y-auto',
    fullHeight && 'max-h-[calc(100%-40px)] sm:max-h-[calc(100%-80px)]',
    fixedHeight && 'h-[calc(100%-40px)] sm:h-[calc(100%-80px)]',
    className
  )

  return (
    <div
      id={modalId}
      className={twMerge(
        isFadingOut ? 'animate-fadeOut' : '',
        animateOnOpen ? 'animate-fadeIn' : '',
        'fixed top-0 bottom-0 left-0 right-0 z-[1011] flex justify-center overflow-y-auto bg-black bg-opacity-60',
        containerClass
      )}
    >
      <div
        ref={ref}
        className={boxClasses}
        style={style}
        data-test={dataTest}
        data-testid={dataTest} // need for jest testing for getByTestId function
      >
        {header && (
          <div className="flex">
            <CloseButton handleClose={handleClose} />
          </div>
        )}

        {children}
      </div>
    </div>
  )
}

export const ModalHeader = ({
  children,
  className,
  style
}: {
  children: any
  className?: string
  style?: any
}) => {
  return (
    <div className={twMerge(clsx('mb-2 px-8 md:px-11', className))} style={style}>
      {children}
    </div>
  )
}

export const ModalHeaderWithClose = ({
  children,
  handleClose,
  className,
  style
}: {
  children: any
  handleClose: () => void
  className?: string
  style?: any
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className={twMerge(clsx('px-8 md:px-11', className))} style={style}>
        {children}
      </div>
      <CloseButton handleClose={handleClose} />
    </div>
  )
}

export const ModalTitle = ({
  children,
  className,
  style
}: {
  children: any
  className?: string
  style?: any
}) => {
  return (
    <div
      className={twMerge(clsx('pb-1 text-xl font-bold md:pb-2 md:text-2xl', className))}
      style={style}
    >
      {children}
    </div>
  )
}

export const ModalSubtitle = ({
  children,
  className,
  style
}: {
  children: any
  className?: string
  style?: any
}) => {
  return (
    <div className={twMerge(clsx('mb-3 text-rb-gray-400', className))} style={style}>
      {children}
    </div>
  )
}

export const ModalContent = ({
  children,
  className,
  style,
  scrollContent = true,
  dataTest,
  hasPadding = true
}: {
  children: any
  className?: string
  style?: any
  scrollContent?: boolean
  dataTest?: string
  hasPadding?: boolean
}) => {
  return (
    <div
      id="rf-modal-content"
      className={twMerge(
        clsx(
          hasPadding && 'px-8 md:px-11',
          scrollContent && 'flex-1 overflow-y-auto',
          className
        )
      )}
      style={style}
      data-test={dataTest}
      data-testid={dataTest}
    >
      {children}
    </div>
  )
}

export const ModalFooter = ({
  children,
  className,
  style
}: {
  children?: any
  className?: string
  style?: any
}) => {
  return (
    <div
      className={twMerge(clsx('flex justify-end py-3 px-8 md:py-8 md:px-11', className))}
      style={style}
    >
      {children}
    </div>
  )
}

export type SetIsModalOpen = React.Dispatch<React.SetStateAction<boolean>>

const defaultProps = {
  isOpen: false
}

interface UseModalProps {
  isOpen: boolean
}

export const useModal = ({ isOpen }: UseModalProps = defaultProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(isOpen)

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const openModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  return { isModalOpen, setIsModalOpen, closeModal, openModal }
}

export default Modal
