export function makeConversationUrl(
  cohortSlug: string,
  topicSlug: string,
  postId?: string
): string {
  let URL = `/cohorts/${cohortSlug}/conversations/${topicSlug}`

  if (postId) {
    URL += `/thread/${postId}`
  }

  return URL
}

export function makeCohortEventDetailsUrl(
  cohortSlug: string,
  eventId: string,
  eventSlug: string
): string {
  return `/cohorts/${cohortSlug}/events/${eventId}-${eventSlug}`
}

export function makeLessonViewerUrl(
  cohortSlug: string,
  cmsProgramSlug: string,
  cmsModuleSlug: string,
  cmsParentSectionSlug?: string,
  cmsLessonSlug?: string
): string {
  let URL = `/cohorts/${cohortSlug}/c/${cmsProgramSlug}/${cmsModuleSlug}`

  if (cmsParentSectionSlug) {
    URL += `/${cmsParentSectionSlug}`
  }

  if (cmsLessonSlug) {
    URL += `/${cmsLessonSlug}`
  }

  return URL
}
