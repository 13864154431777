const ChevronUpThinIcon = ({
  className,
  width,
  height,
  fill
}: {
  className?: string
  width?: string
  height?: string
  fill?: string
}) => (
  <svg
    className={className}
    height={height}
    width={width}
    viewBox="0 0 12 8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.13802 7.13806L0.195211 6.19525L5.99995 0.390514L11.8047 6.19525L10.8619 7.13806L5.99995 2.27613L1.13802 7.13806Z"
      fill={fill}
    />
  </svg>
)

export default ChevronUpThinIcon
