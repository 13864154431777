import Bugsnag, { Event } from '@bugsnag/js'
import { datadogRum } from '@datadog/browser-rum'

// object for adding context to error reporting
//
// example: { sourceComponent: 'ErrorBoundary' }
export type ErrorContextObject = {
  [key: string]: string | number | boolean
}

const notifyError = (
  error: Error | Object | string | unknown,
  context?: ErrorContextObject,
  onErrorCb?: (event: Event) => boolean | void
) => {
  let e: Error

  if (error instanceof Error) {
    e = error
  } else if (typeof error === 'string') {
    e = new Error(error)
  } else if (typeof error === 'object') {
    e = new Error(JSON.stringify(error))
  } else {
    e = new Error('Notifier Unknown Error')
  }
  if (Bugsnag.isStarted()) {
    Bugsnag.notify(e, onError(onErrorCb))
  }

  datadogRum.addError(e, context)
}

const onError = (callback?: (event: Event) => boolean | void) => (event: Event) => {
  if (!event.originalError || !event.originalError.stack) {
    return
  }

  // We really mean it, so making certain the traces are cleaned
  // Clean originalError from the event
  event.originalError.stack = event.originalError.stack
    .split('\n')
    .filter((line: string) => !line.includes('at notifyError'))
    .join('\n')
  event.errors[0].stacktrace = event.errors[0].stacktrace.filter(
    (frame) => frame.method !== 'notifyError'
  )
  callback?.(event)
}

export default notifyError
