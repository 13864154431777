import defaultAvatar from 'default_avatar.svg'
import { SyntheticEvent } from 'react'
import { twMerge } from 'tailwind-merge'

export interface SquarishAvatarImageProps {
  avatarUrl: string
  className?: string
  alt: string
}

/**
 * SquarishAvatarImage
 * returns a styled, almost square avatar image
 */
export const SquarishAvatarImage = ({
  avatarUrl,
  alt,
  className
}: SquarishAvatarImageProps) => {
  const useErrorAvatar = (e: SyntheticEvent<HTMLImageElement, ErrorEvent>) => {
    const imageElement = e.target as HTMLImageElement

    imageElement.src = defaultAvatar
    imageElement.setAttribute('style', 'object-fit: contain')
  }

  return (
    <img
      src={avatarUrl}
      alt={alt}
      className={twMerge('h-[113px] w-[100px] rounded-[20px] object-cover', className)}
      data-testid="squarish-avatar-image"
      onError={useErrorAvatar}
    />
  )
}

export default SquarishAvatarImage
