import clsx from 'clsx'
import * as React from 'react'

interface IContainer {
  children: React.ReactNode
  hasError?: boolean
  isValid?: boolean
}

const Container = ({ hasError, isValid, children }: IContainer) => {
  const inputClassName = `w-full text-rb-gray-300 inline-block mb-2 rounded-sm p-px border border-${
    hasError ? 'rb-destructive-100' : 'rb-gray-100'
  }`
  const inputFocusedClassName = `focus-within:shadow-input${
    hasError ? '-error' : isValid ? '-valid' : ''
  } focus-within:border-rb-${hasError ? 'destructive' : isValid ? 'success-200' : 'blue'}`
  const inputHoverClassName = `hover:border-rb-${
    hasError ? 'destructive' : isValid ? 'success-200' : 'blue'
  }`

  return (
    <div className={clsx(inputClassName, inputFocusedClassName, inputHoverClassName)}>
      {children}
    </div>
  )
}

export interface IWithContainer {
  hasError?: boolean
  isValid?: boolean
}

const withContainer = <P extends object>(
  Element: React.ComponentType<P>
): React.FC<P & IWithContainer> => {
  const WithContainer = (props: IWithContainer) => (
    <Container hasError={props.hasError} isValid={props.isValid}>
      <Element {...(props as P)} />
    </Container>
  )
  return WithContainer
}

export default withContainer
