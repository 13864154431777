const ArrowRight = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.03345 2.17115L6.15126 1.06669L13.0334 7.86669L6.15126 14.6667L5.03345 13.5622L10.7978 7.86669L5.03345 2.17115Z"
      fill="currentColor"
    />
  </svg>
)

export default ArrowRight
