import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface ChildrenProps {
  children: ReactNode
  className?: string
}

const RfHeader1 = ({ children, className }: ChildrenProps) => (
  <h1
    className={twMerge(
      'rf-h1 mb-0',
      className // allow color, margin and padding overrides
    )}
  >
    {children}
  </h1>
)

export default RfHeader1
