import InfoIcon from 'components/icons/InfoIcon'

const MembershipExpiresBeforeSeasonEndsAlert = () => (
  <div className="bg-rb-gray-50-light-blue mb-6 flex items-center rounded border border-rb-blue-100 px-5 py-2">
    <InfoIcon className="mr-3 hidden md:block" fill="#1E87F0" />
    <p className="mb-0">
      Your subscription expires before these live programs end and you’ll lose access
      before completing the program. Renew your subscription in{' '}
      <a className="inline-block" href="/billing">
        Account Settings
      </a>
      .
    </p>
  </div>
)

export default MembershipExpiresBeforeSeasonEndsAlert
