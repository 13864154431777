import { getProgressPercent } from 'components/cards/Content/utils'

import {
  CohortTeamWeekProgressUserPartsFragment,
  SectionCardChildPartsFragment,
  UserProgramPartsFragment
} from 'gql'

import { ModuleProgress } from 'typings/scalars'

export function getModuleProgress(
  userProgram:
    | UserProgramPartsFragment
    | LegacyUserProgram
    | CohortTeamWeekProgressUserPartsFragment,
  cmsModuleId: string
): ModuleProgress {
  if (isUserProgram(userProgram) && userProgram.progress?.[cmsModuleId]) {
    return userProgram.progress[cmsModuleId]
  }
  if (isLegacyUserProgram(userProgram) && userProgram.userProgress[cmsModuleId]) {
    return userProgram.userProgress[cmsModuleId]
  }
  return {}
}

// This function averages progress percentages for all lessons in a section
export function getSectionProgress(
  userProgram: UserProgramPartsFragment | LegacyUserProgram,
  children: SectionCardChildPartsFragment[]
): number {
  if (children.length === 0) return 0
  let sum = 0

  children.forEach((child: SectionCardChildPartsFragment) => {
    if (!child?.cmsModule) return

    const moduleProgress = getModuleProgress(userProgram, child.cmsModule.id)
    const progressPercent = getProgressPercent(moduleProgress, child.id)

    sum += progressPercent
  })

  return sum / children.length
}

type LegacyUserProgram = { userProgress: any }

function isUserProgram(userProgram: any): userProgram is UserProgramPartsFragment {
  return !!userProgram.progress
}

function isLegacyUserProgram(userProgram: any): userProgram is LegacyUserProgram {
  return !!userProgram.userProgress
}
