import React from 'react'
import { twMerge } from 'tailwind-merge'

const LessonIcon = ({ className }: { className?: string }) => (
  <svg
    className={twMerge('h-[12px] w-[12px]', className)}
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 0V9H13.5V0H0.5ZM1.5 1H12.5V8H1.5V1ZM7 2C6.17773 2 5.5 2.67773 5.5 3.5C5.5 3.83984 5.61914 4.1543 5.8125 4.40625C5.32617 4.77148 5 5.34961 5 6H6C6 5.44141 6.44141 5 7 5C7.55859 5 8 5.44141 8 6H9C9 5.34961 8.67383 4.77148 8.1875 4.40625C8.38086 4.1543 8.5 3.83984 8.5 3.5C8.5 2.67773 7.82227 2 7 2ZM7 3C7.28125 3 7.5 3.21875 7.5 3.5C7.5 3.78125 7.28125 4 7 4C6.71875 4 6.5 3.78125 6.5 3.5C6.5 3.21875 6.71875 3 7 3ZM3.5 6.5V7.5H5.5V6.5H3.5ZM6 6.5V7.5H8V6.5H6ZM8.5 6.5V7.5H10.5V6.5H8.5ZM4 9.5V10.5H10V9.5H4Z"
      fill="#0F0F0F"
    />
  </svg>
)

export default LessonIcon
