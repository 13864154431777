import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

import Tooltip from 'components/Tooltip/Tooltip'

import { Component, TagProps, VARIANT_CLASSES } from './helpers'

const Tag = ({
  children,
  className,
  text,
  tooltipContent,
  contentAfter,
  contentBefore,
  variant = 'primary',
  href,
  onClick,
  dataTest
}: PropsWithChildren<TagProps>) => {
  const tag = (
    <Component
      href={href}
      className={twMerge(
        'inline-flex h-6 shrink-0 items-center overflow-hidden rounded px-1.5 text-xs font-medium tracking-normal',
        href ? VARIANT_CLASSES.light : VARIANT_CLASSES[variant],
        href ? 'text-rb-blue-200 hover:text-rb-gray-500 hover:no-underline' : '',
        onClick ? 'cursor-pointer' : '',
        className
      )}
      data-test={dataTest}
      data-testid={dataTest}
      onClick={onClick}
    >
      {contentBefore}
      {text ? <span className="whitespace-nowrap">{text}</span> : children}
      {contentAfter}
    </Component>
  )

  if (tooltipContent) {
    return (
      <Tooltip
        contentWrapperClassname="hover:no-underline cursor-default"
        className="max-w-xs"
        tooltipBody={tooltipContent}
      >
        {tag}
      </Tooltip>
    )
  }
  return tag
}

export default Tag
