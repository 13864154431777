import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

import { ColorOptions } from 'components/typography/TypographyColorOptions'

interface RfParagraphLargeProps {
  className?: string
  color?: ColorOptions
}

const RfParagraphLarge = ({
  children,
  color = ColorOptions.gray400,
  className
}: PropsWithChildren<RfParagraphLargeProps>) => (
  <p
    className={twMerge(
      `m-0 p-0 text-${color}`,
      className, // allow color, margin and padding overrides
      'text-[18.375px] leading-[27.56px] sm:text-[21px] sm:leading-[31.5px]'
    )}
  >
    {children}
  </p>
)

export default RfParagraphLarge
