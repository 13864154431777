const BasicInfoIcon = ({
  className,
  fill = 'white'
}: {
  className?: string
  fill?: string
}) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.50004 13.5333C11.556 13.5333 14.0334 11.056 14.0334 8.00001C14.0334 4.94403 11.556 2.46668 8.50004 2.46668C5.44407 2.46668 2.96671 4.94403 2.96671 8.00001C2.96671 11.056 5.44407 13.5333 8.50004 13.5333ZM8.50004 14.6667C12.1819 14.6667 15.1667 11.6819 15.1667 8.00001C15.1667 4.31811 12.1819 1.33334 8.50004 1.33334C4.81814 1.33334 1.83337 4.31811 1.83337 8.00001C1.83337 11.6819 4.81814 14.6667 8.50004 14.6667Z"
      fill={fill}
    />
    <path
      d="M9.09037 11.499V6.24034H7.90637V11.499H9.09037ZM9.09037 5.62167V4.38434H7.90637V5.62167H9.09037Z"
      fill={fill}
    />
  </svg>
)

export default BasicInfoIcon
