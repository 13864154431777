import React from 'react'
import { renderToString } from 'react-dom/server'

import { InlinePostReply, PostListCommentFragment, PostListReplyFragment } from 'gql'

interface TooltipCopyProps {
  children: React.ReactNode
}

export const TooltipCopy = ({ children }: TooltipCopyProps) => (
  <div className="py-1 px-2.5">{children}</div>
)

export const getNameCopy = (uniqPeople: string[]) => {
  let nameCopy = ''
  if (uniqPeople.length === 1) {
    nameCopy = uniqPeople[0]
  } else if (uniqPeople.length === 2) {
    nameCopy = uniqPeople.join(' and ')
  } else if (uniqPeople.length === 3) {
    nameCopy = `${uniqPeople.slice(0, 2).join(', ')}, and ${uniqPeople[2]}`
  } else {
    const otherCount = uniqPeople.length - 3
    const otherCopy = otherCount === 1 ? 'other' : 'others'
    nameCopy = `${uniqPeople.slice(0, 3).join(', ')}, and ${otherCount} ${otherCopy}`
  }
  return nameCopy
}

interface TooltipProps {
  replies: Array<InlinePostReply | PostListReplyFragment | PostListCommentFragment>
}

const isInlinePostReply = (reply: any): reply is InlinePostReply =>
  'userFullName' in reply

export const getUniquePeopleCollection = ({ replies }: TooltipProps) => {
  const uniqPeople = replies.reduce((peopleCollection: string[], reply) => {
    const userFullName = isInlinePostReply(reply)
      ? reply.userFullName
      : reply.user?.profile?.fullName

    if (!!userFullName && !peopleCollection.includes(userFullName)) {
      return [...peopleCollection, userFullName]
    }
    return peopleCollection
  }, [])
  return uniqPeople
}

export const getTooltipCopy = ({ replies }: TooltipProps) => {
  if (replies.length === 0) {
    return renderToString(<TooltipCopy>No responses yet</TooltipCopy>)
  }

  const uniqPeople = getUniquePeopleCollection({ replies })

  return renderToString(
    <TooltipCopy>{`${getNameCopy(uniqPeople)} responded to this post`}</TooltipCopy>
  )
}
