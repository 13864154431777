import History from 'history'

/**
 * Get full path from react-router's (history's) location object.
 *
 * @example
 *  When current URL is `https://reforge.com/account?param=value#location`
 *
 *  const location = useLocation()
 *  getFullPath(location) === '/account?param=value#location'
 */
export function getFullPath<T>(location: History.Location<T>): string {
  return `${location.pathname}${location.search}${location.hash}`
}
