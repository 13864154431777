import React from 'react'
import { twMerge } from 'tailwind-merge'

const LightningBolt = ({ className }: { className?: string }) => (
  <svg
    className={twMerge('h-[12px] w-[8px]', className)}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 1V5.5L6 6L4.05078 11H4V6.98438L2 6.48438L3.94922 1H4ZM5 0H3.24414L3.00781 0.664062L1.05664 6.15039L0.689453 7.1875L1.75781 7.45508L3 7.76562V12H4.73438L4.98242 11.3633L6.93164 6.36328L7.34375 5.30469L5 4.71875V0Z"
      fill="#0F0F0F"
    />
  </svg>
)

export default LightningBolt
