import { twMerge } from 'tailwind-merge'

import notifyError, { ErrorContextObject } from 'utils/errorNotifier'

interface IError {
  message: string
}

const ErrorMessage = ({
  error,
  className,
  context
}: {
  error?: IError | IError[] | string | null
  className?: string
  context?: ErrorContextObject
}) => {
  if (!error) return null

  const message = getErrorString(error).replace('GraphQL error: ', '')

  if (isErrorReportable(error)) {
    notifyError(error instanceof Error ? error : message, context)
  }

  return (
    <div
      className={twMerge('border border-red-400 bg-red-100 p-6 text-red-900', className)}
    >
      {message}
    </div>
  )
}

const defaultErrorMessage = 'Something went wrong. Please try again.'

export function getErrorString(e: any) {
  if (!e) return defaultErrorMessage

  if (typeof e === 'string') return e
  if (Array.isArray(e)) {
    return e.length
      ? e.map((error) => error.message || error).join(', ')
      : defaultErrorMessage
  }
  // Keep this check last as an Array will still have a typeof 'object'
  if (typeof e === 'object') return e.message || defaultErrorMessage

  return defaultErrorMessage
}

const is403 = (e: any) => e.networkError && e.networkError.statusCode === 403

// Filters for errors to exclude from Bugsnag
const nonReportableFilters = [is403]

function isErrorReportable(e: any) {
  if (!e) return false // It is not reportable if it does not exist
  if (Array.isArray(e)) return true // We report on arrays of errors

  // If the error matches any of the non-reportable filters, do not report
  return !nonReportableFilters.some((filter) => filter(e))
}

export default ErrorMessage
