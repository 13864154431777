type CollectiveBadgeSmallProps = {
  className?: string
}
const CollectiveBadgeSmall = ({ className }: CollectiveBadgeSmallProps) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="18" height="18" rx="2" fill="#E2EC9A" />
    <g clipPath="url(#clip0_121_16155)">
      <path
        d="M4.49499 4.58404C4.45844 4.56983 4.46745 4.52003 4.50705 4.518C5.46956 4.46616 6.43886 4.53236 7.01266 4.6482C8.03142 4.85413 8.94871 5.22273 9.5808 6.12325C9.85224 6.50997 10.0496 6.93634 10.1119 7.37442C10.116 7.40268 10.0924 7.42781 10.0615 7.42781H6.61851C6.59149 7.42781 6.56956 7.40861 6.56769 7.38394C6.49444 6.35962 5.89617 5.12672 4.49499 4.58404Z"
        fill="#080A0A"
      />
      <path
        d="M13.4971 13.416C13.5336 13.4302 13.5246 13.48 13.485 13.4821C12.5225 13.5339 11.5532 13.4677 10.9794 13.3519C9.96062 13.1459 9.04333 12.7773 8.41123 11.8768C8.1398 11.4901 7.94247 11.0637 7.8801 10.6257C7.87602 10.5974 7.89964 10.5723 7.93058 10.5723H11.3737C11.4007 10.5723 11.4226 10.5915 11.4245 10.6161C11.4978 11.6405 12.096 12.8734 13.4971 13.416Z"
        fill="#080A0A"
      />
      <path
        d="M6.34332 7.72895C6.36508 7.70085 6.33363 7.66416 6.29896 7.67712C5.46988 7.98937 1.86087 9.57542 2.59885 11.7302C3.24778 13.607 6.696 13.5283 7.49142 13.4843C7.53103 13.4821 7.53986 13.4324 7.50332 13.4184C6.08005 12.8684 5.48433 11.6069 5.42586 10.5802C5.38354 9.83661 5.59242 8.69894 6.34332 7.72895Z"
        fill="#080A0A"
      />
      <path
        d="M11.6565 10.2711C11.6347 10.2992 11.6662 10.3359 11.7008 10.3229C12.5299 10.0108 16.1391 8.42462 15.4011 6.26982C14.7522 4.39307 11.3038 4.47175 10.5084 4.51562C10.4689 4.51781 10.4599 4.5673 10.4965 4.58151C11.9199 5.13153 12.5156 6.393 12.5741 7.41982C12.6164 8.16343 12.4075 9.30109 11.6566 10.2711H11.6565Z"
        fill="#080A0A"
      />
    </g>
    <defs>
      <clipPath id="clip0_121_16155">
        <rect width="13" height="9" fill="white" transform="translate(2.5 4.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default CollectiveBadgeSmall
