export enum ColorOptions {
  black = 'rb-black',
  gray400 = 'rb-gray-400',
  lightBlack = 'rb-gray-400',
  gray = 'rb-gray-300',
  darkGray = 'rb-gray-500',
  brandExtraDark = 'rb-green-500',
  brandTeal200 = 'rb-teal-200',
  brandTeal300 = 'rb-teal-300',
  white = 'rb-white',
  green100 = 'rb-green-100',
  green500 = 'rb-green-500',
  success200 = 'rb-success-200'
}
