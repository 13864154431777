import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'

import { Season, UserCohort } from 'gql'

import { FULL_DATE_FORMAT, formatInTimezone, getAbbreviatedTimezone } from 'utils/date'

const ProgramSelectorInstructions = ({
  postPayment,
  userCohort,
  upcomingSeason,
  timezone
}: {
  postPayment: boolean
  userCohort?: UserCohort
  upcomingSeason: Season
  timezone: any
}) => {
  const enrollmentsCloseAt = upcomingSeason?.enrollmentsCloseAt
  const enrollmentsCloseAtDate = formatInTimezone(enrollmentsCloseAt, timezone, 'MMMM do')
  const enrollmentsCloseAtTime = formatInTimezone(enrollmentsCloseAt, timezone, 'h:mm a')

  return (
    <>
      <RfParagraphMedium className="mx-0 mt-2.5 mb-3 text-rb-gray-400">
        {!postPayment && (
          <>Select the one you&apos;re most interested in to enroll upon membership.</>
        )}
        {postPayment && !userCohort && (
          <>
            To enroll, select a program from the list below. You can modify your selection
            {enrollmentsCloseAt
              ? ` through ${enrollmentsCloseAtDate} at ${enrollmentsCloseAtTime} ${getAbbreviatedTimezone(
                  enrollmentsCloseAt,
                  timezone
                )}`
              : ' until the live course starts'}
            .
          </>
        )}
        {postPayment && userCohort && (
          <>
            Your current reservation is selected. To modify your reservation, select a new
            program and update.
          </>
        )}{' '}
        Live programs kickoff the week of{' '}
        {formatInTimezone(upcomingSeason.startsAt, timezone, FULL_DATE_FORMAT)}. Programs
        are 4 or 6 weeks in length, with an additional kickoff week.
      </RfParagraphMedium>
      <RfParagraphMediumBold className="mb-6">
        Please note that all Reforge programs are scheduled to run in Pacific Time. Please
        be aware of possible time shifts due to different Daylight Saving Time changes
        around the world.
      </RfParagraphMediumBold>
    </>
  )
}

export default ProgramSelectorInstructions
