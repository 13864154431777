import { twMerge } from 'tailwind-merge'

import { Select, SelectOption } from 'components/forms/Select'

export interface TabFiltersProps {
  filters: SelectOption[]
  setFilter: (filter: string) => void
  activeFilter: string
  className?: string
  preventDropdown?: boolean
  mobileFilterClassName?: string
  desktopFilterClassName?: string
  tabClassName?: string
  onClick?: (filter: string) => void
}

const TabFilters = ({
  className = '',
  activeFilter,
  setFilter,
  filters,
  preventDropdown = false,
  mobileFilterClassName = '',
  desktopFilterClassName = '',
  tabClassName = '',
  onClick
}: TabFiltersProps) => (
  <div className={className}>
    {/* Mobile Filters */}
    <div
      className={twMerge(
        `block md:hidden ${preventDropdown ? 'hidden' : ''}`,
        mobileFilterClassName
      )}
    >
      <Select
        name="Filter"
        showLabel={false}
        options={filters.filter((filter) => filter.id !== activeFilter)}
        onChange={setFilter}
        placeholder={activeFilter}
        value={activeFilter}
      />
    </div>

    {/* Desktop Filters */}
    <div
      className={twMerge(
        `${preventDropdown ? 'flex' : 'hidden'} mb-2 max-w-max text-lg text-rb-gray-300`,
        desktopFilterClassName
      )}
    >
      {filters.map((filter) => (
        <TabFilter
          key={filter.id}
          filter={filter}
          activeFilter={activeFilter}
          setFilter={setFilter}
          className={tabClassName}
          onClick={onClick}
        />
      ))}
    </div>
  </div>
)

const TabFilter = ({
  filter,
  activeFilter,
  setFilter,
  className,
  onClick
}: {
  filter: SelectOption
  activeFilter: string
  setFilter: (filter: string | number) => void
  className?: string
  onClick?: (filter: string | number) => void
}) => {
  const activeClasses = 'border-rb-black border-b-2 text-rb-black'
  const classes = twMerge(
    'font-semibold outline-none border-b-4 border-transparent focus:outline-none mr-5 hover:text-rb-black',
    className,
    activeFilter === filter.id ? activeClasses : ''
  )

  const handleClick = () => {
    setFilter(filter.id)
    onClick?.(filter.id)
  }

  return (
    <button className={classes} onClick={handleClick}>
      {filter.option}
    </button>
  )
}

export default TabFilters
