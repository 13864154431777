import clsx from 'clsx'
import { useState } from 'react'
import UIkit from 'uikit'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import { Modal, ModalContent, ModalFooter } from 'components/Modal'

import { UserCohort } from 'gql'

import notifyError from 'utils/errorNotifier'
import { sendData } from 'utils/sendData'
import { trackProgramEnrollmentAction } from 'utils/tracking/analytics'

interface UnenrollModalProps {
  afterUnenrollment: (userCohortId: any) => void
  selectedUserCohort: UserCohort
  postPayment: boolean | false
  currentUserId: string
  isOpen: boolean
  handleClose: () => void
}

const UnenrollModal = (props: UnenrollModalProps) => {
  const [unenrolled, setUnenrolled] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    selectedUserCohort,
    postPayment,
    currentUserId,
    isOpen,
    handleClose,
    afterUnenrollment
  } = props

  const submit = () => {
    setLoading(true)

    setTimeout(function () {
      sendData(
        `/api/v1/user_cohorts/${selectedUserCohort.id}`,
        'DELETE',
        {},
        (errorMsg: any) => {
          setLoading(false)
          if (errorMsg) {
            UIkit.notification({
              message:
                '<span uk-icon="icon: warning"></span> Problem during unenrollment',
              status: 'danger',
              pos: 'top-right'
            })
            notifyError(
              `problem forfeiting program selection ${JSON.stringify(errorMsg)}`
            )
          } else {
            setUnenrolled(true)
            afterUnenrollment(selectedUserCohort.id)

            trackProgramEnrollmentAction({
              action: 'remove enrollment',
              cms_program_id: selectedUserCohort.programId,
              stage: postPayment ? 'post-payment' : 'pre-payment',
              enrollment_type: postPayment ? 'confirmed enrollment' : 'pre-enrollment',
              user_id: currentUserId
            })

            setTimeout(function () {
              handleClose()
              window.location.reload()
            }, 2000)
          }
        }
      )
    }, 2000)
  }

  const copyClassNames = clsx(
    'px-6 pt-4 pb-8 text-center transition-opacity duration-300 tl:px-[85px] tl:pb-[80px]',
    loading && 'opacity-50'
  )

  return (
    <Modal
      isOpen={isOpen}
      dataTest="unenrollment-modal"
      handleClose={handleClose}
      modalId="unenrollment-modal"
      className="w-[90%] px-0 pb-0 sm:max-w-[926px]"
      fullHeight={false}
      fullWidth={true}
    >
      {!unenrolled && (
        <>
          <ModalContent>
            <div className={copyClassNames}>
              <div className="flex justify-center">
                <SVGIcon name="exclamation" fill="#0F0F0F" width="48" height="48" />
              </div>
              <h3 className="uk-margin-small-bottom mt-7 text-xl font-semibold">
                Are you sure you want to unenroll from{' '}
                {selectedUserCohort && selectedUserCohort.programName}?
              </h3>
              <p className="uk-text-center uk-margin-small-top text-rb-gray-300">
                If so, we understand that things come up and hope to see you in this
                program soon.
              </p>
            </div>
          </ModalContent>

          <ModalFooter className="flex flex-col-reverse items-center justify-end bg-rb-gray-50 p-8 pb-5 tl:flex-row tl:pb-8">
            <Button
              dataTest="unenrollment-modal-cancel-button"
              size="small"
              variant="text-only"
              disabled={loading}
              className="uk-modal-close"
              onClick={handleClose}
            >
              No, keep my seat
            </Button>

            <Button
              dataTest="unenrollment-modal-confirm-button"
              size="small"
              disabled={loading}
              onClick={submit}
              isLoadingSpinner={loading}
            >
              Yes, unenroll
            </Button>
          </ModalFooter>
        </>
      )}

      {unenrolled && (
        <ModalContent dataTest="unenroll-success-modal">
          <div className={copyClassNames}>
            <div className="flex justify-center">
              <SVGIcon name="check-slim" fill="#00C361" width="48" height="48" />
            </div>
            <h3 className="uk-margin-small-bottom mt-7 text-xl font-semibold">
              You have successfully unenrolled from{' '}
              {selectedUserCohort && selectedUserCohort.programName}.
            </h3>
          </div>
        </ModalContent>
      )}
    </Modal>
  )
}

export default UnenrollModal
