import { History } from 'history'
import * as React from 'react'
import { type Location } from 'react-router-dom-v5-compat'

import { ContentLinkInfoPartsFragment } from 'gql'

import { CONCEPTS_INDEX, PROJECTS_INDEX, getPageFromPath } from 'utils/location'
import { PURCHASE_FLOW_URL, getFullPath } from 'utils/url'

import { ModuleProgress } from 'typings/scalars'

export function getProgressPercent(
  userProgress: ModuleProgress,
  cmsSectionId: string | number
): number {
  const completed = userProgress[cmsSectionId] === 'completed'
  if (completed) {
    return 100
  }
  const progressDetails = userProgress[`${cmsSectionId}d`]
  if (progressDetails && typeof progressDetails === 'object') {
    return progressDetails.content_percent || 0
  }
  return 0
}

type HandleClick = (args: {
  e: React.MouseEvent | React.KeyboardEvent
  card: ContentLinkInfoPartsFragment
  currentUser: {
    id: string
    accessPolicyKind: string
    is: { member: boolean }
    can: { viewPurchaseFlow?: boolean | null }
  }
  history: History
  location: Location
  inNewTab?: boolean
  allowPreview?: boolean
  isCohortDashboard?: boolean
  cohortSlug?: string
  additionalTrackingParams?: {}
  openPurchaseFlow: () => void
}) => void

export const handleCardClick: HandleClick = ({
  e,
  card,
  currentUser,
  history,
  location,
  inNewTab = false,
  allowPreview,
  isCohortDashboard = false,
  cohortSlug,
  openPurchaseFlow
}) => {
  if (!card.cmsProgram?.enrolled && card.cmsProgram?.notLaunched) {
    return
  }

  const showMemberContent = currentUser.is.member || allowPreview
  const path = `${isCohortDashboard ? `/cohorts/${cohortSlug}` : ''}${card?.href}`
  const shouldOpenPurchaseFlow = currentUser?.can?.viewPurchaseFlow
  const hrefDestination = showMemberContent
    ? path
    : shouldOpenPurchaseFlow
      ? PURCHASE_FLOW_URL
      : '/account'

  const isProjectsOrConceptsIndexPage = [PROJECTS_INDEX, CONCEPTS_INDEX].includes(
    getPageFromPath()
  )

  if (isProjectsOrConceptsIndexPage && !showMemberContent) {
    if (shouldOpenPurchaseFlow) {
      openPurchaseFlow()
    }
  } else {
    if (e.metaKey || e.ctrlKey || inNewTab) {
      window.open(hrefDestination)
    } else {
      history.push(hrefDestination, { from: getFullPath(location) })
    }
  }
}

export enum ContentPercentComplete {
  FirstQuartile = 25,
  SecondQuartile = 50,
  ThirdQuartile = 75,
  NinetyPercent = 90,
  OneHundredPercent = 100
}

export enum LessonContentLocation {
  ContentViewer = 'content_viewer'
}
