import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { PURCHASE_FLOW_URL, getFullPath } from 'utils/url'

export default function useOpenPurchaseFlow({
  reactivate
}: { reactivate?: boolean } = {}) {
  const location = useLocation()
  const inAppPath = getFullPath(location)

  const navigate = useNavigate()

  const destination = `${PURCHASE_FLOW_URL}/choose-plan${
    reactivate ? '?reactivate=true' : ''
  }`

  function openPurchaseFlow() {
    navigate(destination, {
      state: { from: inAppPath }
    })
  }

  return { openPurchaseFlow, destination }
}
