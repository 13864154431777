import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { ColorOptions } from 'components/typography/TypographyColorOptions'

interface ChildrenProps {
  children: ReactNode
  color?: ColorOptions
  className?: string
}

const RfParagraphSmall = ({
  children,
  color = ColorOptions.gray400,
  className
}: ChildrenProps) => (
  <p
    className={twMerge(
      'm-0 p-0',
      className, // allow margin and padding overrides
      `text-[13px]  leading-[19.5px] sm:text-[14px] sm:leading-[21px] text-${color}`
    )}
  >
    {children}
  </p>
)

export default RfParagraphSmall
