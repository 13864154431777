import Pluralize from 'react-pluralize'
import { twMerge } from 'tailwind-merge'

import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphMiniBold from 'components/typography/RfParagraph/RfParagraphMiniBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { ProgramSelectorScheduledWeekPartsFragment, ScheduledWeek } from 'gql'

import { getMeetingDateAndTimesInText } from 'utils/cohortUtils'
import { getDurationInMinutes } from 'utils/date'

const ScheduledWeekDetails = ({
  scheduledWeeks,
  timezone
}: {
  scheduledWeeks: ProgramSelectorScheduledWeekPartsFragment[]
  timezone: string
}) => {
  const getWeekBlocks = () => {
    return scheduledWeeks.map((scheduledWeek: ScheduledWeek, i) => {
      const { events, numLessons, title, description, id } = scheduledWeek

      const cohortEvents = events.filter((event) => event.kind === 'Cohort Event')
      const meetingTimeText = getMeetingDateAndTimesInText(cohortEvents, timezone)

      const firstEvent = cohortEvents?.[0]
      const eventDuration =
        firstEvent && getDurationInMinutes(firstEvent.startsAtUtc, firstEvent.endsAtUtc)

      let numContentText = null
      if (numLessons) {
        numContentText = (
          <RfParagraphMini color={ColorOptions.gray} className="mt-1">
            <Pluralize singular="Lesson" showCount={true} count={numLessons} />
          </RfParagraphMini>
        )
      }

      return (
        <div
          key={`scheduled_week_${id}`}
          className={twMerge(
            'flex flex-col p-6 xs:flex-row',
            i !== scheduledWeeks.length - 1 && 'border-b-[1px] border-b-rb-gray-100'
          )}
        >
          <RfParagraphMiniBold className="mb-4 flex h-8 min-w-[76px] max-w-[76px] items-center justify-center rounded-[100px] bg-rb-green-50 px-4 py-2 xs:mb-0 xs:mr-12">
            {i === 0 ? 'Kickoff' : `Week ${i}`}
          </RfParagraphMiniBold>
          <div className="flex flex-col">
            <RfParagraphMediumBold>{title}</RfParagraphMediumBold>
            {numContentText}
            <RfParagraphSmall className="mt-4">{description}</RfParagraphSmall>
            {cohortEvents.length > 0 && (
              <>
                <RfParagraphMiniBold className="mt-4">Live Event</RfParagraphMiniBold>
                <RfParagraphMini className="mt-2">
                  {meetingTimeText} • {eventDuration} mins
                </RfParagraphMini>
              </>
            )}
          </div>
        </div>
      )
    })
  }

  return (
    <div className="mt-14 text-black">
      <RfHeader2 className="mt-12 mb-0">Program</RfHeader2>
      <RfParagraphSmall className="mt-4 mb-8">
        Every week, you&apos;ll complete program content, discuss it with your fellow
        members and EIRs, and join a live event that reinforces the key lessons of the
        week.
      </RfParagraphSmall>
      <div className="w-full rounded-lg border-[1px] border-rb-gray-100">
        {getWeekBlocks()}
      </div>
    </div>
  )
}

export default ScheduledWeekDetails
