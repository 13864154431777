export const EVENT_LINK_URL = '/events/links/:eventUUID'
export const COHORT_EVENT_LINK_URL = '/cohorts/:slug/events/links/:eventUUID'
export const EVENT_PAGE_URL = '/events/:eventID-:eventSlug'
export const EVENT_SUMMARY_URL = '/events/:eventID-:eventSlug/summary'
export const EVENT_DETAILS_TAB_URL = '/events/:eventID-:eventSlug'
export const EVENT_ATTENDEES_TAB_URL = '/events/:eventID-:eventSlug/attendees'
export const EVENT_SUMMARY_TAB_URL = '/events/:eventID-:eventSlug/summary'
export const REFORGE_URL = 'https://www.reforge.com'
export const TOC_URL = 'https://www.reforge.com/terms-of-service'
export const LINKEDIN_URL = 'https://linkedin.com/in/:linkedInHandle'
export const TWITTER_URL = 'https://www.twitter.com/:twitterHandle'
export const REIMBURSEMENT_CENTER_URL = '/reimbursement'
export const LESSON_VIEWER_URL =
  '/c/:cmsProgramSlug/:cmsModuleSlug/:cmsSectionParentSlug?/:cmsSectionSlug'
export const CONTENT_VIEWER_URL = '/m/:cmsContentType/:cmsSectionSlug'
export const BLOG_GROWTH_LOOPS = '/blog/growth-loops'
export const BLOG_GROWTH_SYSTEM = '/blog/growth-system'
export const BLOG_GROWTH_METRIC = '/blog/growth-metric-acquisition-monetization-virality'
export const REVIEWS = '/reviews'
export const HOME = '/'
export const COHORT_DASHBOARD_EVENT_DETAILS_TAB_URL =
  '/cohorts/:cohortSlug/events/:eventID-:eventSlug'
export const COHORT_DASHBOARD_EVENT_SUMMARY_TAB_URL =
  '/cohorts/:cohortSlug/events/:eventID-:eventSlug/summary'
export const NEW_PLANS_AND_PRICING_INFO_URL = 'https://www.reforge.com/pricing'
export const PURCHASE_FLOW_URL = '/subscribe'
export const PURCHASE_FLOW_STEP_URL = `${PURCHASE_FLOW_URL}/:step`
export const CREATOR_TYPEFORM_URL = 'https://artifacts.typeform.com/share-my-work'
export const OLD_CREATOR_TYPEFORM_URL = 'https://artifacts.typeform.com/to/CfQmVXNA'
export const CANNY_UPVOTE_TOPICS_URL = 'https://artifact-requests.canny.io/upvote-topics'
