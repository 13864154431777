import { twMerge } from 'tailwind-merge'

const BookmarkFolderIcon = ({ className }: { className?: string }) => (
  <svg
    className={twMerge('h-4 w-4', className)}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.39315 0.00555555C1.04136 0.0283236 0.696956 0.184886 0.442925 0.437516C0.205971 0.673162 0.0591824 0.969703 0.00927991 1.31353C-0.0030933 1.39881 -0.0030933 10.6003 0.00927991 10.6856C0.0242455 10.7887 0.0431927 10.8693 0.0759084 10.9691C0.199935 11.3474 0.47614 11.6651 0.833062 11.8402C1.0255 11.9346 1.19706 11.9805 1.40828 11.9942C1.52913 12.0019 12.4718 12.0019 12.5926 11.9942C12.8039 11.9805 12.9754 11.9346 13.1679 11.8402C13.6153 11.6208 13.9174 11.1973 13.9916 10.6856C13.9984 10.6388 14 9.9504 14 6.99756C14 3.49538 13.9996 3.36436 13.9881 3.2903C13.921 2.85557 13.6891 2.49 13.3279 2.2494C13.133 2.11958 12.8835 2.03076 12.6499 2.00804C12.6016 2.00335 11.6021 2.00124 9.41579 2.00124H6.25164L5.70531 1.27354C5.40483 0.873315 5.14106 0.525697 5.11918 0.501067C4.93116 0.289488 4.65727 0.122256 4.38244 0.0512197C4.28012 0.0247731 4.20254 0.0125606 4.09094 0.00533806C3.97892 -0.00191011 1.50544 -0.00171821 1.39315 0.00555555ZM1.41084 1.00982C1.21969 1.04227 1.05226 1.2036 1.01219 1.39394C1.00384 1.43365 1.00269 1.9906 1.00269 5.99957C1.00269 10.0085 1.00384 10.5655 1.01219 10.6052C1.0486 10.7782 1.18358 10.9223 1.36115 10.9777L1.40916 10.9927H7.00366H12.5982L12.6494 10.9751C12.7953 10.9251 12.9119 10.8168 12.9653 10.6819C12.9757 10.6558 12.9875 10.6143 12.9916 10.5897C12.9972 10.5559 12.9985 9.66521 12.997 6.97197L12.995 3.39906L12.9799 3.35428C12.9239 3.18863 12.7939 3.06363 12.6288 3.01661L12.579 3.00242L9.24083 2.99922L5.90269 2.99603L5.85788 2.9809C5.77028 2.95131 5.71307 2.91704 5.65044 2.85667C5.6314 2.83831 5.33588 2.45049 4.99371 1.99484C4.65155 1.53919 4.35703 1.15285 4.33921 1.1363C4.28257 1.0837 4.22147 1.04932 4.1328 1.02018L4.09119 1.00651L2.76618 1.00559C2.03742 1.00509 1.42752 1.00699 1.41084 1.00982Z"
        fill="#080A0A"
      />
    </g>
  </svg>
)

export default BookmarkFolderIcon
