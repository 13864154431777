import React from 'react'
import { twMerge } from 'tailwind-merge'

const OverviewIcon = ({ className }: { className?: string }) => (
  <svg
    className={twMerge('h-[12px] w-[12px]', className)}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0.234375L11.2969 0.546875L7.98438 1.96875L4.17188 0.53125L3.98438 0.46875L3.79688 0.546875L0.296875 2.04688L0 2.17188V11.7656L0.703125 11.4531L4.01562 10.0312L7.82812 11.4688L8.01562 11.5312L8.20312 11.4531L11.7031 9.95312L12 9.82812V0.234375ZM4.5 1.71875L7.5 2.84375V10.2812L4.5 9.15625V1.71875ZM3.5 1.75V9.17188L1 10.25V2.82812L3.5 1.75ZM11 1.75V9.17188L8.5 10.25V2.82812L11 1.75Z"
      fill="#0F0F0F"
    />
  </svg>
)

export default OverviewIcon
