const InfoIcon = ({
  className,
  fill = '#1E87F0'
}: {
  className?: string
  fill?: string
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18.3C14.584 18.3 18.3 14.584 18.3 10C18.3 5.41604 14.584 1.7 10 1.7C5.41604 1.7 1.7 5.41604 1.7 10C1.7 14.584 5.41604 18.3 10 18.3ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill={fill}
    />
    <path
      d="M10.8855 15.2485V7.36054H9.1095V15.2485H10.8855ZM10.8855 6.43254V4.57654H9.1095V6.43254H10.8855Z"
      fill={fill}
    />
  </svg>
)

export default InfoIcon
