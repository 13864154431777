import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { DefaultAvatarIcon } from 'components/icons'

import { Maybe } from 'gql'

import { getMemberBadge } from 'utils/member'

import { MemberKind } from 'typings/legacy/member'

import { ReactComponent as ReforgeSymbol } from 'images/reforge-logo-symbol.svg'

export interface IAvatarWithBadge {
  hasBadge?: Maybe<boolean>
  avatarUrl?: string | null
  alt?: string
  containerClasses?: string
  imgClasses: string
  kind?: Maybe<MemberKind>
}

const AvatarWithBadge = ({
  hasBadge = false,
  avatarUrl,
  alt = '',
  containerClasses = '',
  imgClasses = '',
  kind
}: IAvatarWithBadge) => {
  const [shouldShowErrorAvatar, setShouldShowErrorAvatar] = useState(!avatarUrl)

  const displayErrorAvatar = () => {
    setShouldShowErrorAvatar(true)
  }

  const imageClassNames = twMerge('rounded-full', imgClasses)
  const badgeBackground = getMemberBadge({ hasBadge, kind })

  return (
    <span
      className={twMerge(
        'relative inline-block rounded-full bg-rb-gray-50',
        containerClasses
      )}
    >
      <span className="absolute bottom-0 left-0 z-[1] h-20 w-20 bg-cover bg-center bg-no-repeat" />
      {shouldShowErrorAvatar ? (
        <DefaultAvatarIcon className={imageClassNames} />
      ) : (
        <img
          className={imageClassNames}
          src={avatarUrl || ''}
          alt={alt}
          onError={displayErrorAvatar}
        />
      )}
      {badgeBackground.showBadge && (
        <div
          className={`absolute bottom-[1%] left-[1%] flex h-[25%] w-[25%] items-center justify-center rounded-sm p-[4%] ${badgeBackground.class}`}
        >
          <ReforgeSymbol
            className={`w-full ${
              badgeBackground.color === 'black' ? 'fill-white' : 'fill-black'
            }`}
          />
        </div>
      )}
    </span>
  )
}

export default AvatarWithBadge
