export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ChatQuery: ['Query'],
    CollectionsQuery: ['Query'],
    EventRelatedResources: ['Artifact', 'CclCourse', 'MarketingCourse'],
    ForumContribution: ['Comment', 'Post', 'Reply'],
    HomepageRecentlyViewedItem: ['Artifact', 'Content', 'Unit'],
    PersonalizationQuery: ['Query'],
    Searchable: ['CmsSection', 'Comment', 'Post', 'Reply', 'User'],
    SsoQuery: ['Query'],
    StripePaymentSources: ['StripeBankAccount', 'StripeSource'],
    SwimlanesQuery: ['Query'],
    UserProfileFieldsInterface: ['UserProfile']
  }
}
export default result
