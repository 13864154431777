import Pluralize from 'react-pluralize'
import { twMerge } from 'tailwind-merge'

import { SVGIcon } from 'components/Icon'
import Tooltip from 'components/Tooltip/Tooltip'

import { InlinePostReply, PostListCommentFragment, PostListReplyFragment } from 'gql'

import { getTooltipCopy } from './responseCountUtils'

export interface ResponseCountProps {
  replies: Array<InlinePostReply | PostListReplyFragment | PostListCommentFragment>
  count: number
  showWord?: boolean | false
  className?: string | null | undefined
}

const ResponseCount = ({ replies, count, showWord, className }: ResponseCountProps) => (
  <Tooltip
    html={true}
    tooltipBody={getTooltipCopy({ replies })}
    className="!z-[1011] !opacity-100"
    contentWrapperClassname="hover:no-underline"
  >
    <div
      className={twMerge(
        'group ml-2.5 flex w-full cursor-pointer items-center justify-center leading-4  text-rb-gray-300 hover:text-rb-teal-200 hover:no-underline',
        className
      )}
    >
      <SVGIcon
        className="text-rb-gray-300 group-hover:text-rb-teal-300"
        name="discussions"
        width="16"
        height="16"
        fill="currentColor"
      />
      <span className="ml-2 flex">
        <span>{count}</span>
        {showWord && (
          <span className="ml-1 hidden sm:block">
            <Pluralize
              singular={'response'}
              plural={'responses'}
              count={count}
              showCount={false}
            />
          </span>
        )}
      </span>
    </div>
  </Tooltip>
)

export default ResponseCount
