export const NOT_SOCIAL_ANSWER = 'Not Interested'

export const ONBOARDING_COMPLETE = 8

export const FUNCTION_OPTIONS = [
  { label: 'Growth', id: '2' },
  { label: 'Product', id: '3' },
  { label: 'Engineering', id: '5' },
  { label: 'Marketing', id: '1' },
  { label: 'Analytics/Business Strategy', id: '4' },
  { label: 'Design', id: '0' }
]

export const SKILLS = [
  {
    title: 'Experimentation',
    suggestions: [
      'Experiment Design',
      'Experiment Ideation',
      'Experiment Process',
      'Statistical Significance'
    ]
  },
  {
    title: 'Leadership',
    suggestions: ['Communicating Strategy', 'Modeling / Forecasting', 'People Management']
  },
  {
    title: 'Marketing',
    suggestions: [
      'Affiliate Programs',
      'Attribution',
      'Brand Marketing',
      'Content Marketing',
      'Conversion Rate Optimization',
      'Display Ads',
      'Facebook Ads',
      'Other Social Media Ads',
      'Paid Marketing',
      'Performance Marketing',
      'Podcast Ads',
      'Search Marketing',
      'Social Media Marketing',
      'Sponsored Content'
    ]
  },
  {
    title: 'Monetization',
    suggestions: ['Discounting', 'Monetization Model', 'Pricing', 'Upgrade Terms']
  },
  {
    title: 'Product Development Skills',
    suggestions: [
      'Competitive Analysis',
      'Customer Research',
      'Data Analysis',
      'Goal Setting',
      'Influencing without Authority',
      'Planning',
      'Presentations',
      'Prioritization',
      'Roadmapping',
      'User Research',
      'Verbal Communication',
      'Written Communication'
    ]
  },
  {
    title: 'Retention & Engagement',
    suggestions: [
      'Activation',
      'Cohort Analysis',
      'Email',
      'Lifecycle Marketing',
      'Notifications',
      'Resurrection',
      'Retention'
    ]
  },
  {
    title: 'Sales',
    suggestions: ['Direct Sales', 'Inside Sales', 'Online Sales']
  },
  {
    title: 'SEO',
    suggestions: [
      'Google Search Console',
      'Link Building',
      'On-Page Optimization',
      'SEO Content',
      'SEO Experimentation'
    ]
  },
  {
    title: 'Virality',
    suggestions: ['Organic', 'Referrals']
  }
]

export const PROGRAM_CATEGORIES = [
  'All Programs',
  'Product',
  'Engineering',
  'Marketing',
  'Growth'
]
