import * as React from 'react'

interface Handler<T> {
  (e: React.KeyboardEvent<T>): void
}

export const onKeyPress =
  <T = Element>(key: string, callback: Handler<T>) =>
  (e: React.KeyboardEvent<T>) =>
    e.key === key && callback && callback(e)

export const onEnterKeyPress =
  <T = Element>(callback: Handler<T>) =>
  (e: React.KeyboardEvent<T>) =>
    onKeyPress('Enter', callback)(e)

export const onSpaceKeyPress =
  <T = Element>(callback: Handler<T>) =>
  (e: React.KeyboardEvent<T>) =>
    onKeyPress(' ', callback)(e)

export const onEscapeKeyPress =
  <T = Element>(callback: Handler<T>) =>
  (e: React.KeyboardEvent<T>) =>
    onKeyPress('Escape', callback)(e)
