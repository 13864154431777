import { Link } from 'react-router-dom'

import { displayToast } from 'components/Toast'
import { CloseIcon } from 'components/icons'

import { onEnterKeyPress } from 'utils/keyboard'

import { ReactComponent as DangerIcon } from 'images/icon--toast-danger.svg'
import { ReactComponent as InfoIcon } from 'images/icon--toast-info.svg'
import { ReactComponent as SuccessIcon } from 'images/icon--toast-success.svg'
import { ReactComponent as WarningIcon } from 'images/icon--toast-warning.svg'

export interface ToastCardProps {
  type?: 'informational' | 'success' | 'warning' | 'destructive'
  message: string
  viewHref?: string
  viewText?: string
  onUndo?: () => void
  dataTest?: string
  ctaText?: string
  onCtaClick?: () => void
}

export const toastOptions = {
  className: '!bg-rb-gray-400 flex items-center p-0 w-full min-h-4 xs:w-[400px] ',
  bodyClassName: 'w-full h-full max-w-xl my-0 py-0 w-screen',
  position: displayToast.POSITION.BOTTOM_CENTER,
  closeButton: <CloseIcon className="mr-4 h-16 w-auto text-white" />
}

const ToastIcon = ({ type = 'success' }: { type?: string }) => {
  const sizeProps = { width: 50, height: 50 }
  switch (type) {
    case 'success':
      return <SuccessIcon {...sizeProps} />
    case 'informational':
      return <InfoIcon {...sizeProps} />
    case 'warning':
      return <WarningIcon {...sizeProps} />
    case 'destructive':
      return <DangerIcon {...sizeProps} />
    default:
      return <SuccessIcon {...sizeProps} />
  }
}

const ToastCard = ({
  type,
  message,
  viewHref,
  viewText = 'VIEW',
  dataTest,
  onUndo = undefined,
  ctaText,
  onCtaClick
}: ToastCardProps) => {
  return (
    <div data-test={dataTest} className="flex items-center rounded-xl bg-rb-gray-400">
      <div className="mr-4">
        <ToastIcon type={type} />
      </div>
      <span className="relative flex-1 text-sm text-white">{message}</span>
      {viewHref && (
        <Link
          className="ml-2 text-sm font-bold text-white no-underline hover:text-rb-gray-100 hover:no-underline"
          to={viewHref}
        >
          {viewText}
        </Link>
      )}
      {ctaText && onCtaClick && (
        <span
          onClick={onCtaClick}
          onKeyUp={onEnterKeyPress(onCtaClick)}
          tabIndex={0}
          role="button"
          className="ml-2 text-sm font-bold text-white hover:text-rb-gray-100"
        >
          {ctaText}
        </span>
      )}
      {onUndo && (
        <span
          onClick={onUndo}
          onKeyUp={onEnterKeyPress(onUndo)}
          tabIndex={0}
          role="button"
          className="ml-2 text-sm font-bold text-white hover:text-rb-gray-100"
        >
          UNDO
        </span>
      )}
    </div>
  )
}

export default ToastCard
