import { privateApolloClient } from 'apolloClient'
import { useEffect, useReducer, useState } from 'react'
import UIkit from 'uikit'

import MembershipExpiresBeforeSeasonEndsAlert from 'domains/Enrollment/MembershipExpiresBeforeSeasonEndsAlert'
import ProgramSelectorHeading from 'domains/Enrollment/ProgramSelectorHeading'
import ProgramSelectorInstructions from 'domains/Enrollment/ProgramSelectorInstructions'
import ProgramSelectorMessageModal from 'domains/Enrollment/ProgramSelectorMessageModal'
import SelectProgramCard from 'domains/Enrollment/SelectProgramCard'

import Button from 'components/Button'
import {
  CloseButton,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle
} from 'components/Modal'
import TabFilters from 'components/TabFilters'

import { PROGRAM_CATEGORIES } from 'constants/onboarding'

import { CmsProgram, Season, UserCohort, useGetProgramSelectorInfoQuery } from 'gql'

import { getMeetingTimesInText } from 'utils/cohortUtils'
import { FULL_DATE_FORMAT, formatInTimezone } from 'utils/date'
import notifyError from 'utils/errorNotifier'
import { getPageFromPath } from 'utils/location'
import { sendData } from 'utils/sendData'
import { track } from 'utils/tracking/analytics'

import { UserCohortEnrollPayload } from 'typings/payloads'

import { ReactComponent as ChevronLeft } from 'images/chevron-left.svg'

const useIsSubscriptionExpiringBeforeEndOfSeason = ({
  user,
  season
}: {
  user: any
  season: Season
}) => {
  if (!user) return {}

  const activeSubscription = user.subscriptions?.active

  const isSubscriptionExpiringBeforeEndOfSeason =
    season?.endsAt &&
    activeSubscription?.isCanceled &&
    new Date(activeSubscription?.expiresAt) <= new Date(season?.endsAt)

  return {
    isActiveSubscriptionCanceled: activeSubscription?.isCanceled,
    subscriptionExpiresAt: activeSubscription?.expiresAt,
    isSubscriptionExpiringBeforeEndOfSeason
  }
}

interface IProgramSelectorMessageModalContainer {
  isOpen: boolean
  onClose: () => void
  sourceFlow: string
  expandedProgramId?: string
  selectedProgramId?: string
  afterSubmit?: (userCohort: UserCohort) => void | null
  postPayment: boolean
  canUnenroll?: boolean | false
  onUnenroll?: () => void
  isMoreInfoScreen?: boolean
}

const ProgramSelectorMessageModalContainer = ({
  isOpen,
  onClose,
  sourceFlow,
  expandedProgramId,
  afterSubmit,
  postPayment = true,
  canUnenroll,
  onUnenroll,
  isMoreInfoScreen,
  selectedProgramId
}: IProgramSelectorMessageModalContainer) => {
  const { loading, data } = useGetProgramSelectorInfoQuery()

  if (
    !isOpen ||
    loading ||
    !data?.currentUser ||
    !data.upcomingSeason ||
    !data.lastActiveSeason ||
    !data.enrollmentSeason
  ) {
    return null // do not use a loading state component here - it causes the queries on the page to refire
  }

  return (
    <ProgramSelectorModal
      isOpen={isOpen}
      onClose={onClose}
      sourceFlow={sourceFlow}
      expandedProgramId={expandedProgramId}
      selectedProgramId={selectedProgramId}
      afterSubmit={afterSubmit}
      postPayment={postPayment}
      season={data.enrollmentSeason}
      canUnenroll={canUnenroll}
      onUnenroll={onUnenroll}
      isMoreInfoScreen={isMoreInfoScreen}
      upcomingSeason={data.upcomingSeason}
      lastActiveSeason={data.lastActiveSeason}
      user={data.currentUser}
      enrollmentSeason={data.enrollmentSeason}
    />
  )
}

interface IProgramSelectorModal {
  isOpen: boolean
  onClose: () => void
  sourceFlow: string
  expandedProgramId: any | null
  selectedProgramId: any
  afterSubmit: any
  postPayment: any
  season: any
  canUnenroll?: boolean | false
  onUnenroll?: () => void
  isMoreInfoScreen?: boolean
  upcomingSeason: Season
  lastActiveSeason: Season
  user: any
  enrollmentSeason: Season
}

const initialState = {
  selectedProgramId: undefined,
  selectedProgramName: undefined,
  selectedProgramSlug: undefined,
  selectedProgramMeetingTimes: undefined,
  expandedProgramId: undefined,
  loading: false,
  forfeited: false
}

const ProgramSelectorModal = ({
  isOpen,
  onClose,
  sourceFlow,
  expandedProgramId,
  selectedProgramId,
  afterSubmit,
  postPayment,
  season,
  user,
  enrollmentSeason,
  canUnenroll,
  onUnenroll,
  isMoreInfoScreen: _isMoreInfoScreen,
  upcomingSeason,
  lastActiveSeason
}: IProgramSelectorModal) => {
  const {
    isActiveSubscriptionCanceled,
    subscriptionExpiresAt,
    isSubscriptionExpiringBeforeEndOfSeason = false
  } = useIsSubscriptionExpiringBeforeEndOfSeason({
    user,
    season: enrollmentSeason
  })

  const { recommendedPrograms: allPrograms } = user
  const programs = allPrograms.filter((program: CmsProgram) => program.canEnroll)
  const userCohort: UserCohort | undefined =
    user.cohorts?.current &&
    user.cohorts.current.find(
      (cohort: { seasonId: string }) => cohort.seasonId === enrollmentSeason.id
    )

  const programApplicationId = userCohort && (userCohort.programApplicationId as any)

  const [filter, setFilter] = useState(PROGRAM_CATEGORIES[0])
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const [isMoreInfoScreen, setIsMoreInfoScreen] = useState(_isMoreInfoScreen)

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    initialState
  )

  useEffect(() => {
    if (programs.length === 0 || !state.selectedProgramId) return

    const selectedProgram = programs.find(
      (program: any) => program.id === state.selectedProgramId
    )

    if (!selectedProgram) return

    let eventMeetingTimes = ''
    if (selectedProgram.cohort && selectedProgram.cohort.eventTimes) {
      eventMeetingTimes = getMeetingTimesInText(
        selectedProgram.cohort.eventTimes,
        user.timezone,
        true
      )
    }

    setState({
      selectedProgramName: selectedProgram.name,
      selectedProgramSlug: selectedProgram.slug,
      selectedProgramMeetingTimes: eventMeetingTimes
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedProgramId])

  useEffect(() => {
    setState({ selectedProgramId })
  }, [selectedProgramId])

  useEffect(() => {
    setState({ expandedProgramId })
  }, [expandedProgramId])

  const getSeasonName = () => {
    return `${season.year}-${season.name}`
  }

  const setSelectedProgram = (programId: any) => {
    setState({ selectedProgramId: programId })
    if (programId) {
      // @ts-ignore - 'Program List - Action' event is not defined in Segment JIRA#REF-5159
      track('Program List - Action', {
        action: 'selects program',
        cms_program_id: programId,
        source_flow: sourceFlow,
        user_id: user.id,
        location: getPageFromPath(),
        season_name: getSeasonName()
      })
    }
  }

  const setExpandedProgram = (programId: any) => {
    setState({ expandedProgramId: programId })
    if (programId) {
      // @ts-ignore - 'Program List - Action' event is not defined in Segment JIRA#REF-5159
      track('Program List - Action', {
        action: 'clicks learn more',
        cms_program_id: programId,
        source_flow: sourceFlow,
        user_id: user.id,
        location: getPageFromPath(),
        season_name: getSeasonName()
      })
    }
  }

  const canSubmit = () => {
    return state.selectedProgramId && !state.loading
  }

  const sortedPrograms = programs.slice().sort((a: any, b: any) => {
    // Show the previously selected program first (if there is one)
    if (selectedProgramId) {
      if (a.id === selectedProgramId && b.id !== selectedProgramId) return -1
      if (a.id !== selectedProgramId && b.id === selectedProgramId) return 1
    }

    // 'recommendationRanking' is the GraphQL field name; 'recommended' is the pre-GraphQL field name.
    // Once we use GraphQL for the other caller of this component (Home.tsx), we can get rid of the
    // 'recommended' field.
    const rankingForA = a.recommendationRanking || a.recommended
    const rankingForB = b.recommendationRanking || b.recommended

    // Sort recommended against non-recommended
    if (rankingForA && !rankingForB) return -1
    if (!rankingForA && rankingForB) return 1

    // Sort recommended against each other (recommendationRanking/recommended is a number, 1, 2, 3 - denotes the order)
    if (rankingForA < rankingForB) return -1
    if (rankingForA > rankingForB) return 1

    // Sort by name
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
  })

  const skipReservation = () => {
    // @ts-ignore - 'Program List - Action' event is not defined in Segment JIRA#REF-5159
    track('Program List - Action', {
      action: 'dismisses modal',
      source_flow: sourceFlow,
      user_id: user.id,
      location: getPageFromPath(),
      season_name: getSeasonName()
    })
    onClose()
  }

  const backToProgramList = (fireTrack = true) => {
    if (fireTrack) {
      // @ts-ignore - 'Program Details - Action' event is not defined in Segment JIRA#REF-5159
      track('Program Details - Action', {
        action: 'clicks back to program list',
        cms_program_id: state.selectedProgramId,
        user_id: user.id,
        location: getPageFromPath(),
        season_name: getSeasonName()
      })
    }

    setIsMoreInfoScreen(false)
    setExpandedProgram(null)
  }

  const submit = () => {
    setState({ loading: true })
    if (state.expandedProgramId) {
      // @ts-ignore - 'Program Details - Action' event is not defined in Segment JIRA#REF-5159
      track('Program Details - Action', {
        action: 'clicks enroll',
        cms_program_id: state.selectedProgramId,
        user_id: user.id,
        location: getPageFromPath(),
        season_name: getSeasonName()
      })
    } else {
      // @ts-ignore - 'Program List - Action' event is not defined in Segment JIRA#REF-5159
      track('Program List - Action', {
        action: 'clicks enroll',
        cms_program_id: state.selectedProgramId,
        user_id: user.id,
        location: getPageFromPath(),
        season_name: getSeasonName()
      })
    }

    const method = userCohort && !state.forfeited ? 'PATCH' : 'POST'
    const url =
      userCohort && !state.forfeited
        ? `/api/v1/user_cohorts/${userCohort.id}`
        : '/api/v1/user_cohorts'

    const data: UserCohortEnrollPayload = {
      cms_program_id: state.selectedProgramId,
      program_application_id: programApplicationId
    }

    sendData(url, method, data, (errorMsg: any, responseData: any) => {
      if (errorMsg) {
        setState({ loading: false })
        const message = errorMsg.errors || 'There was a problem saving your selection'
        UIkit.notification({
          message: `<span uk-icon='icon: warning'></span> ${JSON.stringify(
            message
          )},&nbsp;try reloading this page`,
          status: 'danger',
          pos: 'top-right'
        })
        notifyError(`problem saving program selection ${JSON.stringify(errorMsg)}`)
      } else if (responseData) {
        /* Hide the seat reservation banner after user reserves a seat. */
        const banner = document.getElementById('banner')
        if (banner) {
          banner.style.height = '0px'
          banner.style.padding = '0px'
        }

        setState({ loading: false })
        if (postPayment) {
          /* show the modal (with seat confirmation text) */
          setMessageModalOpen(true)

          setTimeout(function () {
            if (afterSubmit) {
              afterSubmit(responseData)
            }
          }, 500)
        } else {
          if (afterSubmit) {
            afterSubmit(responseData)
          }
        }
      }
    })
  }

  const currentProgram = programs.find(
    ({ id }: { id: any }) => id === state.selectedProgramId
  )

  const isSubscriptionExpiringBeforeEndOfProgram =
    isActiveSubscriptionCanceled &&
    new Date(subscriptionExpiresAt || '') <= new Date(currentProgram?.cohort.endsAt)

  const subscriptionExpireDateString = formatInTimezone(
    subscriptionExpiresAt,
    user.timezone,
    FULL_DATE_FORMAT
  )

  const handleMessageModalClose = () => {
    // resetStore() needs to be placed here to give the user a chance to read the post-enrollment modal.
    // Calling prior to this modal being shown would prematurely render the entire page
    privateApolloClient.resetStore()
    setMessageModalOpen(false)
  }

  const handleCloseButton = () => {
    if (state.expandedProgramId && !isMoreInfoScreen) {
      backToProgramList(true)
    } else {
      onClose()
    }
  }

  return (
    <>
      <Modal
        modalId="program-selector"
        isOpen={isOpen}
        handleClose={onClose}
        fullWidth={true}
        className="relative max-w-[800px] xs:mx-[40px]"
        header={false}
      >
        <div className="absolute top-0 right-0 z-[1] flex pr-2">
          <CloseButton handleClose={handleCloseButton} />
        </div>

        <ModalContent hasPadding={!state.expandedProgramId} className="overflow-auto">
          <ProgramSelectorMessageModal
            isSubscriptionExpiringBeforeEndOfProgram={
              isSubscriptionExpiringBeforeEndOfProgram
            }
            subscriptionExpireDateString={subscriptionExpireDateString}
            open={messageModalOpen}
            onClose={handleMessageModalClose}
            programName={state.selectedProgramName}
            meetingTimes={state.selectedProgramMeetingTimes}
            programSlug={state.selectedProgramSlug}
            upcomingSeason={season}
          />

          {!state.expandedProgramId && (
            <>
              <ModalTitle className="pt-14">
                <ProgramSelectorHeading
                  userCohort={userCohort}
                  postPayment={postPayment}
                />
              </ModalTitle>

              {isSubscriptionExpiringBeforeEndOfSeason && (
                <MembershipExpiresBeforeSeasonEndsAlert />
              )}

              <ProgramSelectorInstructions
                postPayment={postPayment}
                userCohort={userCohort}
                upcomingSeason={season}
                timezone={user.timezone}
              />
              <TabFilters
                className="mb-8"
                filters={PROGRAM_CATEGORIES.map((filter: string) => ({
                  id: filter,
                  option: filter
                }))}
                activeFilter={filter}
                setFilter={setFilter}
                preventDropdown
              />
            </>
          )}

          {state.expandedProgramId && currentProgram && (
            <SelectProgramCard
              key={`program_${currentProgram.id}`}
              program={currentProgram}
              selected={state.selectedProgramId === currentProgram.id}
              expanded={state.expandedProgramId === currentProgram.id}
              setSelectedProgram={setSelectedProgram}
              setExpandedProgram={setExpandedProgram}
              containerId="rf-modal-content"
              timezone={user.timezone}
              season={season}
              sourceFlow={sourceFlow}
              subscriptionExpireDate={
                isSubscriptionExpiringBeforeEndOfProgram && subscriptionExpireDateString
              }
              isMoreInfoScreen={isMoreInfoScreen}
              upcomingSeason={upcomingSeason}
              lastActiveSeason={lastActiveSeason}
            />
          )}

          {!state.expandedProgramId && (
            <>
              {sortedPrograms
                .filter(
                  (p: any) =>
                    filter === 'All Programs' || p.categoryNames.includes(filter)
                )
                .map((program: any) => (
                  <SelectProgramCard
                    key={`program_${program.id}`}
                    program={program}
                    selected={state.selectedProgramId === program.id}
                    expanded={state.expandedProgramId === program.id}
                    setSelectedProgram={setSelectedProgram}
                    setExpandedProgram={setExpandedProgram}
                    timezone={user.timezone}
                    season={season}
                    sourceFlow={sourceFlow}
                    subscriptionExpireDate={
                      isSubscriptionExpiringBeforeEndOfProgram
                        ? subscriptionExpireDateString
                        : ''
                    }
                    upcomingSeason={upcomingSeason}
                    lastActiveSeason={lastActiveSeason}
                  />
                ))}
            </>
          )}
        </ModalContent>

        <ModalFooter className="items-center border-t-2 !py-4 pl-0 pr-4 xs:pl-4 xs:pr-8 md:pl-6 md:pr-12">
          {state.expandedProgramId ? (
            <Button
              variant="text-only"
              size="small"
              onClick={isMoreInfoScreen ? onClose : backToProgramList.bind(this, true)}
              iconBefore={<ChevronLeft width={16} height={16} />}
              className="px-4"
            >
              Back
            </Button>
          ) : (
            <Button
              variant="text-only"
              onClick={skipReservation}
              size="small"
              className="px-4"
            >
              Cancel
            </Button>
          )}
          <div className="ml-auto flex items-center">
            {!state.expandedProgramId && canUnenroll && (
              <Button variant="text-only" size="small" onClick={onUnenroll}>
                Unenroll
              </Button>
            )}
            {isMoreInfoScreen ? (
              <Button
                size="small"
                variant="outline"
                onClick={backToProgramList.bind(this, false)}
                dataTest="cohort-preview-change-enrollment"
              >
                Change Enrollment
              </Button>
            ) : (
              <Button size="small" onClick={submit} disabled={!canSubmit()}>
                Enroll
              </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ProgramSelectorMessageModalContainer
