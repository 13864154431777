import { typeChildren, typeFunc, typeString, typeStyle } from 'constants/types'

const Link = ({
  'data-test': dataTest,
  href,
  to,
  target,
  className,
  style,
  children,
  onClick
}) => (
  <a
    data-test={dataTest}
    href={href || to}
    target={target}
    className={`text-rb-teal-200 no-underline hover:text-rb-teal-600 hover:underline ${
      className || ''
    }`}
    style={style}
    onClick={onClick}
  >
    {children}
  </a>
)

Link.propTypes = {
  'data-test': typeString,
  'to': typeString,
  'href': typeString,
  'target': typeString,
  'className': typeString,
  'style': typeStyle,
  'children': typeChildren.isRequired,
  'onClick': typeFunc
}

export default Link
