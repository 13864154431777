import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import ReactTooltip from 'react-tooltip'
import { twMerge } from 'tailwind-merge'

import UnenrollModal from 'domains/CohortHistory/UnenrollModal'
import ProgramSelectorModal from 'domains/Enrollment/ProgramSelectorModal'

import Loading from 'components/Loading'

import { UserCohort, useModifyEnrollmentInfoQuery } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'

import { ReactComponent as Pencil } from './pencil-icon.svg'

const useModifyEnrollment = () => {
  const [isUnenrollModalOpen, setIsUnenrollModalOpen] = useState(false)
  const [isProgramSelectorModalOpen, setIsProgramSelectorModalOpen] = useState(false)

  const handleOnUnenroll = () => {
    setIsUnenrollModalOpen(true)
    setIsProgramSelectorModalOpen(false)
  }

  return {
    isUnenrollModalOpen,
    isProgramSelectorModalOpen,
    setIsUnenrollModalOpen,
    setIsProgramSelectorModalOpen,
    handleOnUnenroll
  }
}

interface ModifyEnrollmentProps {
  id: string
  className?: string
  pencilClassName?: string
  hidePencil?: boolean
  isModifyModalOpen?: boolean
  onModifyModalClose?: () => void
  expandedProgramId?: string
  isMoreInfoScreen?: boolean
}

const ModifyEnrollment = ({
  id,
  className,
  pencilClassName,
  hidePencil = false,
  isModifyModalOpen = false,
  onModifyModalClose,
  expandedProgramId,
  isMoreInfoScreen = false
}: ModifyEnrollmentProps) => {
  const {
    isUnenrollModalOpen,
    isProgramSelectorModalOpen,
    setIsUnenrollModalOpen,
    setIsProgramSelectorModalOpen,
    handleOnUnenroll
  } = useModifyEnrollment()

  useEffect(() => {
    if (isModifyModalOpen) {
      setIsProgramSelectorModalOpen(true)
    }
  }, [isModifyModalOpen, setIsProgramSelectorModalOpen])

  const { data, loading } = useModifyEnrollmentInfoQuery()

  if (!data || loading) {
    return <Loading />
  }

  const currentUser = data.currentUser

  const modalContainer = document.getElementById('rf-modal-container-portal')

  const handleCloseProgramSelectorModal = () => {
    setIsProgramSelectorModalOpen(false)
    onModifyModalClose?.()
  }

  return (
    <>
      {!hidePencil && (
        <>
          <span
            data-testid="modify-enrollment-pencil"
            tabIndex={0}
            role="button"
            data-for={`modify-enrollment-tooltip-${id}`}
            data-tip="modify"
            className={twMerge('ml-4 normal-case', className)}
            onClick={() => setIsProgramSelectorModalOpen(true)}
            onKeyUp={onEnterKeyPress(() => setIsProgramSelectorModalOpen(true))}
          >
            <Pencil
              className={twMerge(
                'hover:fill-rb-blue-100',
                pencilClassName || 'fill-current'
              )}
              width={20}
              height={20}
            />
          </span>

          <ReactTooltip
            id={`modify-enrollment-tooltip-${id}`}
            place="top"
            effect="solid"
            backgroundColor="#4D4D4D"
            className="text-xs font-normal normal-case leading-[14.4px] "
          >
            Modify enrollment
          </ReactTooltip>
        </>
      )}

      {modalContainer &&
        isProgramSelectorModalOpen &&
        createPortal(
          <ProgramSelectorModal
            isOpen={true}
            onClose={handleCloseProgramSelectorModal}
            postPayment={true}
            sourceFlow="Cohort History"
            canUnenroll={true}
            onUnenroll={handleOnUnenroll}
            selectedProgramId={currentUser?.cohorts?.latestCurrent?.programId}
            expandedProgramId={expandedProgramId}
            isMoreInfoScreen={isMoreInfoScreen}
          />,
          modalContainer
        )}

      {modalContainer &&
        isUnenrollModalOpen &&
        createPortal(
          <UnenrollModal
            isOpen={true}
            handleClose={() => setIsUnenrollModalOpen(false)}
            afterUnenrollment={() => {}}
            selectedUserCohort={currentUser?.cohorts?.latestCurrent as UserCohort}
            postPayment={currentUser?.is?.member as boolean}
            currentUserId={currentUser?.id as string}
          />,
          modalContainer
        )}
    </>
  )
}

export default ModifyEnrollment
