import React from 'react'
import { twMerge } from 'tailwind-merge'

const ProjectIcon = ({ className }: { className?: string }) => (
  <svg
    className={twMerge('h-[12px] w-[12px]', className)}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.12001 13.56V11.88H11.12V0.440002H0.880005V13.56H9.12001ZM8.00001 12.44V10.76H10V1.56H2.00001V12.44H8.00001Z"
      fill="#0F0F0F"
    />
  </svg>
)

export default ProjectIcon
