import { twMerge } from 'tailwind-merge'

import { SquarishAvatarImage } from 'components/SquarishAvatarImage'

import { UserProfileImagePartsFragment } from 'gql'

export type HostImage = {
  thumbnailUrl?: string | null
  name?: string | null
}

export const userProfileToHostImage = (
  userProfile: UserProfileImagePartsFragment
): HostImage => ({
  thumbnailUrl: userProfile.avatarUrl,
  name: userProfile.fullName
})

export interface SingleHostImageProps {
  host: HostImage
  containerClassName?: string
  imgClassName?: string
}

export interface MultipleHostImagesProps {
  hosts: HostImage[]
  containerClassName?: string
  imgClassName?: string
  secondImageClassName?: string
}

export const SingleHostImage = ({
  containerClassName,
  imgClassName,
  host
}: SingleHostImageProps) => (
  <div className={containerClassName}>
    {host?.thumbnailUrl && (
      <img
        className={twMerge('-mr-[2.5px]', imgClassName)}
        src={host?.thumbnailUrl}
        alt={host?.name || 'Host picture'}
        style={{
          clipPath: 'polygon(25% 0, 100% 0, 100% 64%, 73% 100%, 25% 100%, 0 54%)'
        }}
      />
    )}
  </div>
)

export const MultipleHostImages = ({
  hosts,
  containerClassName,
  imgClassName,
  secondImageClassName
}: MultipleHostImagesProps) => (
  <div className={twMerge('relative', containerClassName)}>
    {hosts
      .slice(0, 2)
      .map(
        (host, i) =>
          host?.thumbnailUrl && (
            <SquarishAvatarImage
              key={`host-img-${i}`}
              avatarUrl={host?.thumbnailUrl}
              alt={host?.name || 'Host picture'}
              className={twMerge(
                i === 0
                  ? 'absolute'
                  : twMerge('relative mt-[94px] ml-[94px]', secondImageClassName),
                'rounded',
                imgClassName
              )}
            />
          )
      )}
  </div>
)
