const ConfettiCheckmark = () => (
  <svg
    width="147"
    height="97"
    viewBox="0 0 147 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M100.639 17.9695L110.848 30.8713L104.209 31.2961L93.9995 18.3943L100.639 17.9695Z"
      fill="#016AD2"
    />
    <path
      opacity="0.4"
      d="M30.5297 49.2848L42.8123 46.0842L43.3989 48.3766L30.5296 51.4034L30.5297 49.2848Z"
      fill="#016AD2"
    />
    <path
      opacity="0.4"
      d="M3.24312 70.493L17.3339 61.9998L15.5895 66.4411L1.49875 74.9343L3.24312 70.493Z"
      fill="#B3D0ED"
    />
    <path
      opacity="0.4"
      d="M122.243 61.493L136.334 52.9998L134.59 57.4411L120.499 65.9343L122.243 61.493Z"
      fill="#B3D0ED"
    />
    <path
      opacity="0.4"
      d="M132.664 25.7034C132.664 25.7034 130.561 27.8586 128.339 28.9522C125.371 30.4121 122.388 30.6201 122.388 30.6201L123.378 26.71C123.378 26.71 125.776 26.9136 127.658 26.0683C130.082 24.9792 132.573 22.2231 132.573 22.2231L132.664 25.7034Z"
      fill="#B3D0ED"
    />
    <path
      opacity="0.4"
      d="M111.467 46.9545C111.467 46.9545 108.588 48.0592 106.093 47.9975C102.763 47.9151 100 46.5251 100 46.5251L102.698 43C102.698 43 104.747 44.5021 106.822 44.638C109.495 44.813 113 43.2938 113 43.2938L111.467 46.9545Z"
      fill="#B3D0ED"
    />
    <path
      opacity="0.5"
      d="M60.8921 17.0624L45.0003 12.8042L49.4093 10.9799L65.3012 15.2381L60.8921 17.0624Z"
      fill="#1E87F0"
    />
    <path
      opacity="0.5"
      d="M27.132 91.5182L18.9991 83.0052L22.9702 82.959L31.1032 91.472L27.132 91.5182Z"
      fill="#1E87F0"
    />
    <path
      opacity="0.4"
      d="M77.0904 59.3506L84.6622 44.744L87.7758 50.6233L80.204 65.2298L77.0904 59.3506Z"
      fill="#B3D0ED"
    />
    <path
      opacity="0.5"
      d="M55.5364 32.6755L62.2117 26.0002L64.5432 31.4045L57.8679 38.0798L55.5364 32.6755Z"
      fill="#B3D0ED"
    />
    <path
      opacity="0.5"
      d="M142.176 82.7491L134 78.0289L138.785 74.0866L146.961 78.8068L142.176 82.7491Z"
      fill="#B3D0ED"
    />
    <circle cx="78" cy="64" r="25" fill="#1E87F0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.8311 56.6643L74.9188 73.3315L66.4 65.0709L68.0244 63.3958L74.9056 70.0685L90.1933 55.0024L91.8311 56.6643Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M80.3062 1.74222L92.2737 9.31184L87.9673 10.0233L75.9998 2.45366L80.3062 1.74222Z"
      fill="#016AD2"
    />
    <circle cx="43" cy="34" r="3" fill="#D1E3F4" />
    <circle opacity="0.8" cx="35" cy="75" r="3" fill="#1E87F0" />
    <circle opacity="0.8" cx="121.5" cy="50.5" r="1.5" fill="#1E87F0" />
    <circle opacity="0.8" cx="95" cy="34" r="1" fill="#B3D0ED" />
    <circle opacity="0.5" cx="121.5" cy="91.5" r="1.5" fill="#016AD2" />
    <circle opacity="0.5" cx="20.5" cy="42.5" r="1.5" fill="#016AD2" />
    <circle cx="75" cy="20" r="3" fill="#B3D0ED" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.4315 43.5843L90.2002 44.2312C89.9387 44.307 89.6576 44.2729 89.4218 44.1367L80.7922 39.1545C85.0963 39.633 89.0707 41.2041 92.4315 43.5843Z"
      fill="#016AD2"
    />
    <path
      d="M80.17 31.1097L94.4183 39.3359L88.8776 41.9558C88.5809 42.096 88.2343 42.0818 87.9501 41.9178L74.1556 33.9535L80.17 31.1097Z"
      fill="#B3D0ED"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.0035 64.422C53.0012 64.2816 53 64.1409 53 64C53 62.2825 53.1732 60.6055 53.503 58.9855C53.5161 58.9835 53.5291 58.9814 53.5421 58.9794C57.256 58.3868 61.507 54.9678 61.507 54.9678L60.7104 60.9895C60.7104 60.9895 57.1679 63.5817 53.7378 64.2882C53.4919 64.3389 53.2469 64.3833 53.0035 64.422Z"
      fill="#016AD2"
    />
    <path
      d="M58.9547 57.2341C58.9547 57.2341 56.3122 60.7392 53.2325 62.4067C49.1208 64.6329 44.6838 64.704 44.6838 64.704L45.2281 58.0734C45.2281 58.0734 48.8844 58.6539 51.5113 57.3808C54.8957 55.7405 57.9775 51.2391 57.9775 51.2391L58.9547 57.2341Z"
      fill="#B3D0ED"
    />
    <path
      d="M37.4667 15.8372C37.4667 15.8372 36.4014 19.8883 34.5976 22.8894C32.1894 26.8959 29.0484 29.5606 29.0484 29.5606L27.8937 24.5214C27.8937 24.5214 30.6307 22.7804 32.2039 20.3268C34.2308 17.1657 35.3771 12.1467 35.3771 12.1467L37.4667 15.8372Z"
      fill="#B3D0ED"
    />
  </svg>
)

export default ConfettiCheckmark
