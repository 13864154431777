import clsx from 'clsx'

import { EyeglassIcon } from './icons'

const Search = ({
  className,
  onChange,
  placeholder = 'Search',
  name = 'search',
  fullWidth = true
}: ISearch) => {
  const inputClasses = [
    'pl-10 pr-5 w-full bg-rb-gray-50 py-2.5 outline-none',
    fullWidth && 'w-full',
    className
  ]

  const removeClearButton = `
    /* clears the 'X' from Internet Explorer */
    .rf-search > input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
    .rf-search > input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

    /* clears the 'X' from Chrome */
    .rf-search > input[type="search"]::-webkit-search-decoration,
    .rf-search > input[type="search"]::-webkit-search-cancel-button,
    .rf-search > input[type="search"]::-webkit-search-results-button,
    .rf-search > input[type="search"]::-webkit-search-results-decoration { display: none; }
  `

  return (
    <div className="rf-search relative">
      <style>{removeClearButton}</style>
      <input
        type="search"
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        className={clsx(inputClasses)}
        autoComplete="off"
      />

      <EyeglassIcon className="absolute bottom-1/4 left-3 h-5 w-5 text-rb-gray-300" />
    </div>
  )
}

export interface ISearch {
  className?: string
  onChange: (e: any) => void
  placeholder: string
  name?: string
  fullWidth?: boolean
}

export default Search
