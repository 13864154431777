import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import FieldError from 'components/forms/FieldError'
import { IFormElementBase } from 'components/forms/formTypes'

const withValidation = <P extends object>(
  Element: React.ComponentType<P>
): React.FC<P & IFormElementBase> => {
  const WithValidation = ({ name, ...props }: IFormElementBase) => {
    const { register, control, errors, triggerValidation } = useFormContext()

    const currentValue = props.defaultValue || props.value

    const hasError = errors && errors[name]

    const [isValid, setIsValid] = useState(!hasError && currentValue)

    const onBlur = async (event: any) => {
      const result = await triggerValidation(name)

      setIsValid(result)
      props.onBlur?.(event)
    }

    return (
      <div className="relative w-full">
        {/* Relative positioning is needed as filed error is has an absolute position */}
        <Element
          {...(props as P)}
          name={name}
          register={register}
          control={control}
          onBlur={(event: any) => {
            // ensure event properties are available after running this handler
            event.persist?.()
            onBlur(event)
          }}
          hasError={hasError}
          isValid={isValid}
        />
        <FieldError name={name} errors={errors} />
      </div>
    )
  }
  return WithValidation
}

export default withValidation
