import { UserCohort } from 'gql'

const ProgramSelectorHeading = ({
  userCohort,
  postPayment
}: {
  userCohort?: UserCohort | undefined
  postPayment: boolean
}) => {
  return (
    <h3 className="mb-5 pr-5 text-base font-semibold text-black tl:text-xl">
      {!postPayment && (
        <>
          Based on your selections, we think these live programs could be relevant for
          you.
        </>
      )}
      {postPayment && !userCohort && (
        <>
          Program reservations are now open! Based on your responses, we think these live
          programs could be a good fit for you.
        </>
      )}
      {postPayment && userCohort && <>Modify enrollment</>}
    </h3>
  )
}

export default ProgramSelectorHeading
