const LinkedInIcon = ({ width = 18, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 16"
    fill="currentColor"
  >
    <path d="M5.4 14.382h-3V5.884h3v8.498ZM3.9 4.75c-.83 0-1.5-.635-1.5-1.417s.672-1.416 1.5-1.416c.827 0 1.5.635 1.5 1.416 0 .782-.673 1.417-1.5 1.417Zm12.3 9.63h-2.884v-4.135c0-.986-.02-2.255-1.5-2.255-1.502 0-1.732 1.075-1.732 2.184v4.207H7.2V5.878h2.768V7.04h.04c.385-.669 1.326-1.374 2.73-1.374 2.922 0 3.462 1.762 3.462 4.052v4.664Z" />
  </svg>
)

export default LinkedInIcon
