type BrowserWindow = typeof window & { opera: string }

export const isMobileDevice = () => {
  return /Mobi|Android/i.test(
    navigator.userAgent || navigator.vendor || (window as BrowserWindow).opera
  )
}

export const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    // iPads starting with iOS 13 no longer can be differentiated
    // from Mac OS, so the following touch screen check is also needed
    // for these cases https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
    (navigator.userAgent.includes('Mac') && isTouchScreen())
  )
}

export const isTouchScreen = () => {
  return window.matchMedia('(hover: none)').matches || navigator.maxTouchPoints > 1
}
