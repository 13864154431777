import { ReactNode } from 'react'

import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

interface ChildrenProps {
  children: ReactNode
  color?: ColorOptions
  className?: string
}

const RfParagraphMiniBold = ({ children, color, className }: ChildrenProps) => (
  <RfParagraphMini color={color} className={className}>
    <span className="font-medium">{children}</span>
  </RfParagraphMini>
)

export default RfParagraphMiniBold
