import CohortInfoTag from 'domains/Enrollment/CohortPreview/CohortInfoTag'
import Header from 'domains/Enrollment/CohortPreview/Header'
import HostAndCollaboratorDetails from 'domains/Enrollment/CohortPreview/HostAndCollaboratorDetails'
import ProgramDetails from 'domains/Enrollment/CohortPreview/ProgramDetails'
import ScheduledWeekDetails from 'domains/Enrollment/CohortPreview/ScheduledWeekDetails'

import { FAQs } from 'components/FAQs'

import { ProgramSelectorProgramPartsFragment, ScheduledWeek, Season } from 'gql'

import { pluralize } from 'utils/stringUtils'

export interface CohortPreviewProps {
  program: ProgramSelectorProgramPartsFragment
  timezone: string
  season: Season
  isMoreInfoScreen?: boolean
}

const CohortPreview = ({
  program,
  timezone,
  season,
  isMoreInfoScreen = false
}: CohortPreviewProps) => {
  const scheduledWeeks = program.cohort?.scheduledWeeks.filter(
    (scheduledWeek: ScheduledWeek) => !scheduledWeek.postCohort
  )

  return (
    <div data-testid="cohort-preview-modal">
      <Header program={program} season={season} />
      <div className="mt-10 px-6 xs:px-12">
        <CohortInfoTag
          season={season}
          timezone={timezone}
          isMoreInfoScreen={isMoreInfoScreen}
          eventTimes={program.cohort?.eventTimes || []}
        />
        <ProgramDetails program={program} />
        {program.cohort?.hosts && (
          <HostAndCollaboratorDetails
            hosts={program.cohort.hosts}
            label={pluralize('Your host', 'Your hosts', program.cohort.hosts.length)}
            blurb="Your Course experience is hosted by seasoned experts, Executives in Residence (EIRs), who cover key strategies and tactics to bring the content to life."
          />
        )}
        {scheduledWeeks && (
          <ScheduledWeekDetails scheduledWeeks={scheduledWeeks} timezone={timezone} />
        )}
        {program.cohort?.programCollaborators && (
          <HostAndCollaboratorDetails
            hosts={program.cohort.programCollaborators}
            label={pluralize(
              'Program creator',
              'Program creators',
              program.cohort.programCollaborators.length
            )}
            blurb="Our programs are grounded in real experience from experts actively tackling the key challenges you face at work and are built alongside the Reforge team of experts."
          />
        )}
        <FAQs season={season} />
      </div>
    </div>
  )
}

export default CohortPreview
