import clsx from 'clsx'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import Link from 'components/Link'
import { Modal, ModalContent, ModalFooter } from 'components/Modal'
import ConfettiCheckmark from 'components/icons/ConfettiCheckmark'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'

const ProgramSelectorMessageModal = ({
  isSubscriptionExpiringBeforeEndOfProgram,
  programName,
  meetingTimes,
  open,
  onClose,
  subscriptionExpireDateString
}: {
  isSubscriptionExpiringBeforeEndOfProgram: boolean
  programName?: string
  meetingTimes?: string
  programSlug?: string
  upcomingSeason?: any
  open: boolean
  onClose: () => void
  subscriptionExpireDateString: string
}) => {
  const [loading] = useState(false)
  const history = useHistory()

  const onReactivateClick = () => {
    history.push('/billing')
  }

  return (
    <Modal
      isOpen={open}
      handleClose={onClose}
      containerClass="z-[1011]"
      scrollContent={false}
    >
      <ModalContent scrollContent={false}>
        <div
          className={clsx(
            'flex flex-col items-center px-6 pt-4 pb-8 transition-opacity duration-300',
            loading && 'opacity-50'
          )}
        >
          <ConfettiCheckmark />
          <div className="pt-6 text-center text-xl font-medium sm:text-justify sm:text-2xl">
            You’re enrolled in the <span className="font-bold">{programName}</span> live
            program!
          </div>
          {meetingTimes && (
            <>
              <RfParagraphMediumBold className="mt-1 pb-4 text-center sm:mt-8 sm:text-justify">
                Meeting {meetingTimes}
              </RfParagraphMediumBold>
              <div className="pb-4">
                <RfParagraphMediumBold className="text-center">
                  Please note that all Reforge programs are scheduled to run in PT.
                  Depending on which timezone you&apos;re joining us from, your clock may
                  switch for Daylight Savings in the final week(s) of your program.{' '}
                </RfParagraphMediumBold>
              </div>
            </>
          )}
          <RfParagraphMedium className="hidden text-center sm:block sm:text-justify">
            Welcome to the program. You can modify your enrollment in your{' '}
            <Link to="/cohort-history">Cohort History</Link>.
          </RfParagraphMedium>
          {isSubscriptionExpiringBeforeEndOfProgram && (
            <>
              <div className="hidden py-4 text-center sm:block sm:text-justify">
                Your subscription expires before this live program ends and you&apos;ll
                lose access on {subscriptionExpireDateString}. Reactivate your
                subscription for continued access or modify your enrollment in{' '}
                <a href="/billing">Account Settings</a>.
              </div>
              <div className="block rounded bg-rb-green-75/20 p-4 sm:hidden">
                Your subscription expires before this program ends. You’ll lose access on
                March 30. Renew in <a href="/billing">Account Settings</a>.
              </div>
            </>
          )}
        </div>
      </ModalContent>
      <div className="relative">
        <ModalFooter className="justify-center sm:justify-end">
          <div className="-mt-6 flex flex-col sm:mt-0 sm:flex-row sm:items-center">
            {isSubscriptionExpiringBeforeEndOfProgram && (
              <div className="mb-2 hidden items-center justify-end sm:absolute sm:left-[44px] sm:mb-0 sm:flex">
                <Button
                  variant="outline"
                  disabled={loading}
                  className="text-rb-gray-500"
                  onClick={onReactivateClick}
                >
                  Reactivate
                </Button>
              </div>
            )}
            <div className="mb-3 flex sm:mb-0">
              <Button onClick={onClose} size="small" disabled={loading}>
                Done
              </Button>
            </div>
          </div>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default ProgramSelectorMessageModal
