import { MouseEvent, PropsWithChildren, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import Tooltip from 'components/Tooltip/Tooltip'
import { CloseIcon } from 'components/icons'

import Tag from './Tag'

interface SelectableTagProps {
  text: string
  onChange?: (selected: boolean, e: MouseEvent) => void
  selected?: boolean
  closeTooltipContent?: ReactNode
  className?: string
}

const SelectableTag = ({
  text,
  children,
  onChange,
  selected = false,
  closeTooltipContent,
  className
}: PropsWithChildren<SelectableTagProps>) => {
  const handleClick = (e: MouseEvent) => {
    if (onChange) {
      onChange(!selected, e)
    }
  }

  const getCloseIcon = () => {
    if (selected) {
      if (closeTooltipContent) {
        return (
          <Tooltip
            tooltipBody={closeTooltipContent}
            className="max-w-xs"
            contentWrapperClassname="ml-1 text-rb-gray-400 hover:text-rb-gray-400"
          >
            <CloseIcon className="w-3.5" />
          </Tooltip>
        )
      }

      return <CloseIcon className="ml-1 w-3.5" />
    }

    return null
  }

  return (
    <Tag
      text={text}
      variant="light"
      onClick={handleClick}
      dataTest="selectable-tag"
      contentAfter={getCloseIcon()}
      className={twMerge(className, !selected ? 'bg-rb-gray-50 font-normal' : 'selected')}
    >
      {children}
    </Tag>
  )
}

export default SelectableTag
