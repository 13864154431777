import { PropsWithChildren } from 'react'

import RfHeader1 from 'components/typography/RfHeader/RfHeader1'

interface RfHeader1Props {
  className?: string
}

const RfHeader1Bold = ({ children, className }: PropsWithChildren<RfHeader1Props>) => (
  <RfHeader1 className={className}>
    <span className="font-medium">{children}</span>
  </RfHeader1>
)

export default RfHeader1Bold
