const PlayIcon = ({ className, fill }: { className?: string; fill?: string }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0667 7.99998C14.0667 11.056 11.5893 13.5333 8.53333 13.5333C5.47736 13.5333 3 11.056 3 7.99998C3 4.944 5.47736 2.46665 8.53333 2.46665C11.5893 2.46665 14.0667 4.944 14.0667 7.99998ZM15.2 7.99998C15.2 11.6819 12.2152 14.6666 8.53333 14.6666C4.85144 14.6666 1.86667 11.6819 1.86667 7.99998C1.86667 4.31808 4.85144 1.33331 8.53333 1.33331C12.2152 1.33331 15.2 4.31808 15.2 7.99998ZM7.16667 10.3094L11.1667 7.99998L7.16667 5.69058V10.3094Z"
      fill={fill || 'white'}
    />
  </svg>
)

export default PlayIcon
