const ChevronRightThinIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.533203 1.17145L1.65101 0.0669937L8.5332 6.86699L1.65101 13.667L0.533203 12.5625L6.29758 6.86699L0.533203 1.17145Z"
    />
  </svg>
)

export default ChevronRightThinIcon
