import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { humanizeCamelCase } from 'utils/stringUtils'

export interface ILabel {
  disabled?: boolean
  id?: string
  label?: string
  name?: string
  className?: string
}

const Label = ({ disabled, id, label, name, className }: ILabel) => (
  <label
    className={twMerge(
      'mb-2.5 block text-lg font-medium leading-5',
      disabled ? 'text-neutral-500' : '',
      className
    )}
    htmlFor={id || name}
  >
    {label || humanizeCamelCase(name || '')}
  </label>
)

interface IWithlabel extends ILabel {
  showLabel?: boolean
}

const withLabel = <P extends object>(
  Element: React.ComponentType<P>,
  labelClassName?: string
): React.FC<P & ILabel> => {
  const WithLabel = ({ disabled, id, label, showLabel = true, ...props }: IWithlabel) => (
    <>
      {showLabel && (
        <Label
          disabled={disabled}
          id={id}
          label={label}
          name={props.name}
          className={labelClassName}
        />
      )}
      <Element {...(props as P)} disabled={disabled} id={id} />
    </>
  )

  return WithLabel
}

export default withLabel
