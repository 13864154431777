import { twMerge } from 'tailwind-merge'

const CheckIcon = ({ className, ...rest }: { className?: string }) => (
  <svg
    className={twMerge('fill-current', className)}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1651 4.76032L7.08483 16.6655L1 10.765L2.16024 9.56853L7.07541 14.3348L17.9952 3.57324L19.1651 4.76032Z"
    />
  </svg>
)

export default CheckIcon
