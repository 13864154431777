import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphMiniBold from 'components/typography/RfParagraph/RfParagraphMiniBold'

import { CohortEventTimes, Season } from 'gql'

import { formatCohortOpensAt, getMeetingTimesInText } from 'utils/cohortUtils'

const CohortInfoTag = ({
  season,
  timezone,
  eventTimes,
  isMoreInfoScreen
}: {
  season: Season
  timezone: string
  eventTimes: CohortEventTimes[]
  isMoreInfoScreen: boolean
}) => {
  const formattedOpensAt = formatCohortOpensAt(season.showCohortViewerAt, timezone)
  const meetingTimesInText = getMeetingTimesInText(eventTimes, timezone, true)

  const getBoldedText = () => {
    if (isMoreInfoScreen && formattedOpensAt) {
      return 'Course opens'
    } else if (!isMoreInfoScreen && meetingTimesInText) {
      return 'Meets'
    } else {
      return formattedOpensAt ? 'Course opens' : 'Meets'
    }
  }

  const getCtaText = () => {
    if (isMoreInfoScreen && formattedOpensAt) {
      return formattedOpensAt
    } else if (!isMoreInfoScreen && meetingTimesInText) {
      return meetingTimesInText
    } else {
      return formattedOpensAt || meetingTimesInText
    }
  }

  return formattedOpensAt || meetingTimesInText ? (
    <div
      className="relative flex w-fit rounded-[100px] bg-rb-green-50 px-4 py-2"
      data-testid="cohort-preview-info-tag"
    >
      <RfParagraphMiniBold className="mr-2 whitespace-nowrap">
        {getBoldedText()}
      </RfParagraphMiniBold>
      <RfParagraphMini className="whitespace-nowrap">{getCtaText()}</RfParagraphMini>
    </div>
  ) : null
}

export default CohortInfoTag
