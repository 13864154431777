import notifyError from './errorNotifier'

export function sendData(uri, method, data, callback) {
  const csrfToken = (document.head.querySelector('[name~=csrf-token]') || {}).content
  window
    .fetch(uri, {
      method: method,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
      return response.json().then((data) => {
        if (response.status >= 200 && response.status < 400) {
          callback(null, data)
        } else {
          const message = { status: response.status, errors: data.errors }
          callback(message)
        }
      })
    })
    .catch((err) => {
      callback(err)
    })
    .catch((err) => {
      notifyError(err)
      callback(err)
    })
}

export function sendFormData(uri, method, data) {
  const csrfToken = (document.head.querySelector('[name~=csrf-token]') || {}).content
  const responsePromise = window.fetch(uri, {
    method: method,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRF-TOKEN': csrfToken,
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: new URLSearchParams(data)
  })

  responsePromise.catch((err) => {
    notifyError(err)
  })

  return responsePromise
}
