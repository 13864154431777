import type * as H from 'history'

import {
  makeCohortEventDetailsUrl,
  makeConversationUrl,
  makeLessonViewerUrl
} from 'utils/url/cohortUrls'
import { makeUrl } from 'utils/url/makeUrl'

export { makeUrl, makeConversationUrl, makeCohortEventDetailsUrl, makeLessonViewerUrl }
export * from 'utils/url/constants'
export * from 'utils/url/setSearchParam'
export * from 'utils/url/getFullPath'
export * from 'utils/url/makeSearchParamsString'

export const slugify = (string: string) => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const getTableOfContentsSlug = ({ title, id }: { title: string; id: string }) => {
  return `${slugify(title)}-${id}`
}

export const getCanonicalOrigin = () => {
  if (process.env.TARGET_ENV === 'production') return 'https://www.reforge.com'

  return typeof window !== 'undefined' && window.location.origin
    ? window.location.origin
    : process.env.NEXT_PUBLIC_HOST
}

export const getCanonicalUrl = (path: string) => {
  const acceptedSearchParams = ['page', 'lastId', 'reverse']
  const url = new URL(`${getCanonicalOrigin()}${path}`)

  for (const entry of Array.from(url.searchParams.entries())) {
    if (!acceptedSearchParams.includes(entry[0])) {
      url.searchParams.delete(entry[0])
    }
  }

  return url.toString()
}

/*
  Get query param value from url
  @param {string} key - query param key
  @param {string} search - url search string: window.location.search
  @returns {string} - query param value

  > https://www.reforge.com/programs/retention?page=2
  > getQueryParamValue('?page=2', 'page')
  > '2'
*/
export const getQueryParamValue = (search: string, key: string) => {
  const urlParams = new URLSearchParams(search)
  return urlParams.get(key)
}

/*
  Get all query params from url and return as object
  @param {string} search - url search string: window.location.search
  @returns {object} - query params

  > https://www.reforge.com/programs/retention?page=2&lastId=123
  > getQueryParamsAsObject()
  > { page: '2', lastId: '123' }
*/
export const getQueryParamsAsObject = (search: string) => {
  const urlParams = new URLSearchParams(search)
  const params: { [key: string]: string } = {}

  for (const [key, value] of urlParams.entries()) {
    params[key] = value
  }

  return params
}

/*
  Remove specific query param from url and set new url in browser
  @param {string} key - query param key
  @returns {string} - new url

  > https://www.reforge.com/programs/retention?q=asdf&page=2#somehash
  > removeQueryParamFromUrl(history, '?q=asdf&page=2', 'page')
  > https://www.reforge.com/programs/retention?q=asdf#somehash
*/
export const removeQueryParamFromUrl = (
  history: H.History,
  search: string,
  key: string
) => {
  const urlParams = new URLSearchParams(search)
  urlParams.delete(key)

  history.replace(
    `${window.location.pathname}?${urlParams.toString()}${window.location.hash}`
  )
}

export const isAuthPath = () => {
  const pathname = window.location.pathname
  const authPaths = [
    '/auth/google_oauth2',
    '/auth/azure_activedirectory_v2',
    '/auth/linkedin'
  ]

  return authPaths.some((path) => pathname.includes(path))
}
