import { twMerge } from 'tailwind-merge'

const ConceptIcon = ({ className }: { className?: string }) => (
  <svg
    className={twMerge('h-[13px] w-[13px]', className)}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00132 0.763428L15.1919 3.88979V12.1208L8.00132 15.2472L0.810699 12.1208V3.88979L8.00132 0.763428ZM1.94403 4.63285V11.3777L8.00132 14.0113L14.0586 11.3777V4.63285L8.00132 1.99925L1.94403 4.63285Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.60332 3.74164L8.568 6.76976V14.6292H7.43466V7.51283L1.15143 4.78098L1.60332 3.74164Z"
      fill="#0F0F0F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.77536 6.62162L14.3993 3.74164L14.8512 4.78098L8.22725 7.66097L7.77536 6.62162Z"
      fill="black"
    />
  </svg>
)

export default ConceptIcon
