import React from 'react'
import { twMerge } from 'tailwind-merge'

const MaintenanceIcon = ({ className }: { className?: string }) => (
  <svg
    className={twMerge('h-[12px] w-[12px]', className)}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.625 0.375L1.34375 0.640625L0.640625 1.34375L0.375 1.625L0.578125 1.95312L1.625 3.70312L1.76562 3.95312H3.23438L5.23438 5.9375C3.44727 7.73242 1.17383 10.0137 1.09375 10.0938C0.310547 10.877 0.308594 12.1602 1.10938 12.9062C1.89062 13.6777 3.16406 13.6973 3.90625 12.9062C3.91211 12.9004 3.91602 12.8965 3.92188 12.8906L7 9.79688L10.0938 12.9062L10.1406 12.9375C10.9258 13.6777 12.1738 13.6875 12.9062 12.9062V12.8906H12.9219C13.6875 12.1074 13.6953 10.834 12.9062 10.0938L12.8906 10.0781L10.2812 7.48438C12.0371 7.32031 13.4121 5.83789 13.4375 4.04688H13.4531C13.4551 4.03711 13.4531 4.02539 13.4531 4.01562C13.4531 4.00977 13.4531 4.00586 13.4531 4C13.502 3.42188 13.377 2.86914 13.0781 2.39062L12.7344 1.85938L10.4062 4.1875L9.70312 3.45312L12.0781 1.07812L11.3906 0.796875C10.9883 0.625 10.5234 0.5 10 0.5C8.07812 0.5 6.5 2.07812 6.5 4C6.5 4.20898 6.54492 4.39062 6.57812 4.57812C6.35938 4.79688 6.19531 4.97656 5.9375 5.23438L3.95312 3.25V1.76562L3.70312 1.625L1.95312 0.578125L1.625 0.375ZM10 1.5C10.0703 1.5 10.1191 1.54102 10.1875 1.54688L8.29688 3.4375L8.64062 3.79688L10.0469 5.25L10.3906 5.60938L12.375 3.625C12.3848 3.74023 12.4668 3.82422 12.4531 3.95312V4C12.4531 5.37695 11.3301 6.5 9.95312 6.5C9.76953 6.5 9.54688 6.45703 9.29688 6.40625L9.03125 6.35938L8.84375 6.54688L3.20312 12.2031H3.1875V12.2188C2.83203 12.6074 2.21094 12.6172 1.79688 12.2031V12.1875H1.78125C1.39258 11.832 1.38281 11.2109 1.79688 10.7969C1.98633 10.6074 5.65625 6.90625 7.45312 5.10938L7.65625 4.90625L7.57812 4.625C7.53711 4.46289 7.5 4.18359 7.5 4C7.5 2.62305 8.62305 1.5 10 1.5ZM1.78125 1.625L2.95312 2.34375V2.84375L2.84375 2.95312H2.34375L1.625 1.78125L1.78125 1.625ZM9.09375 7.70312L12.2031 10.7969V10.8125H12.2188C12.6074 11.168 12.6172 11.7891 12.2031 12.2031H12.1875V12.2188C11.832 12.6074 11.2109 12.6172 10.7969 12.2031L7.70312 9.09375L9.09375 7.70312Z"
      fill="#0F0F0F"
    />
  </svg>
)

export default MaintenanceIcon
