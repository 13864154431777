export const green = '#00C361'

export const DEFAULT_AVATAR_TAILWIND_COLORS = [
  'bg-blue-500',
  'bg-green-500',
  'bg-yellow-500',
  'bg-red-500',
  'bg-purple-500',
  'bg-pink-500',
  'bg-indigo-500',
  'bg-orange-500',
  'bg-teal-500',
  'bg-lime-500',
  'bg-emerald-500',
  'bg-cyan-500',
  'bg-fuchsia-500',
  'bg-violet-500',
  'bg-rose-500',
  'bg-amber-500',
  'bg-sky-500'
]

export const getColorFromFullName = (fullName: string) => {
  let hash = 5381
  for (let i = 0; i < fullName.length; i++) {
    hash = (hash * 33) ^ fullName.charCodeAt(i)
  }
  const index = Math.abs(hash) % DEFAULT_AVATAR_TAILWIND_COLORS.length
  return DEFAULT_AVATAR_TAILWIND_COLORS[index]
}
