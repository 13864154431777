import PropTypes from 'prop-types'
import { useEffect } from 'react'

import SelectProgramCardTime from 'domains/Enrollment/SelectProgramCardTime'

import { SVGIcon } from 'components/Icon'

import { showNewBadge } from 'utils/programUtils'

import CohortPreview from './CohortPreview/CohortPreview'

const checkmarkCircleGreen = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#00C361" />
    <rect
      width="26.6667"
      height="26.6667"
      transform="translate(7.22253 6.66693)"
      fill="#00C361"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.776 13.0139L16.669 28.8874L8.55591 21.0201L10.1029 19.4248L16.6565 25.7798L31.2161 11.4311L32.776 13.0139Z"
      fill="white"
    />
  </svg>
)

const SelectProgramCard = ({
  program,
  selected,
  setSelectedProgram,
  expanded,
  setExpandedProgram,
  containerId,
  timezone,
  season,
  sourceFlow,
  subscriptionExpireDate,
  isMoreInfoScreen,
  upcomingSeason,
  lastActiveSeason
}) => {
  const {
    id: programId,
    heroImageUrl,
    programImage,
    name,
    recommended,
    recommendationRanking,
    synopsis,
    launchAt
  } = program

  const { eventTimes, weekCount } = program.cohort || {}

  useEffect(() => {
    if (expanded && containerId && document.getElementById(containerId)) {
      document.getElementById(containerId).scrollTop = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded])

  const learnMore = (e) => {
    e.preventDefault()
    e.stopPropagation() /* dont actually select the card */

    setSelectedProgram(programId)
    setExpandedProgram(programId)
  }

  const selectProgram = () => {
    if (expanded) return false
    setSelectedProgram(programId)
  }

  const isRecommended = recommended || recommendationRanking

  return (
    <div
      onClick={selectProgram}
      className={`uk-card uk-card-default mb-[15px] min-h-[145px] cursor-pointer border-[1.25px] border-solid text-left transition-[border-color] duration-[0.3s] hover:border-[1.25px] hover:border-rb-success-100 ${
        selected && 'border-1.25 border-rb-success-100'
      } ${expanded && 'cursor-default border-none shadow-none'}`}
    >
      {!expanded && (
        <>
          <div>
            <div
              className="hidden tl:absolute tl:block tl:h-full tl:w-[255px] tl:bg-rb-gray-50 tl:bg-cover tl:bg-center tl:bg-no-repeat tl:opacity-50 tl:hover:opacity-100"
              style={{ backgroundImage: `url(${heroImageUrl})` }}
            />
            <div className="uk-position-absolute justify-content top-[15px] left-[15px] z-10 flex h-[50px] w-[50px] items-center rounded-full bg-white shadow-content-card tl:h-[40px] tl:w-[40px]">
              {selected ? (
                checkmarkCircleGreen
              ) : (
                <img className="w-auto p-1.5" src={programImage} alt={name} />
              )}
            </div>
          </div>

          <div className="py-[21px] pr-[20px] pl-[80px] tl:pl-[270px]">
            <h3 className="mb-[10px] pr-[28px] text-base font-semibold text-black tl:absolute tl:top-[65px] tl:left-[15px] tl:max-w-[230px] tl:text-[21px]">
              {name}
            </h3>
            <div className="relative">
              <SelectProgramCardTime
                durationInWeeks={weekCount}
                eventTimes={eventTimes}
                timezone={timezone}
              />
            </div>
            <p className="mt-0 mb-[10px] text-[13px] text-rb-gray-400 tl:max-w-[380px] tl:text-[14px]">
              <span>{`${synopsis} `}</span>
              <a className="inline-flex" onClick={learnMore}>
                Learn More
                <SVGIcon
                  className="ml-2 cursor-pointer"
                  name="right-arrow-long"
                  fill="#2199e8"
                  width="14"
                  height="9"
                />
              </a>
            </p>
            <div className="relative top-0 right-0 md:absolute md:mt-6 md:mr-5">
              <div className="flex items-start md:flex-col md:items-end">
                {isRecommended && (
                  <span
                    className="mr-2 block text-xs md:mb-2 md:mr-0"
                    uk-tooltip="title: <div class='py-1 px-2.5'>Recommended based on your role & current work</span>; cls: uk-active rf-tooltip-down-arrow; pos: top; offset: 10"
                  >
                    <div className="rounded bg-rb-blue-200 py-[3px] px-1.5 text-xs font-semibold text-white">
                      Recommended
                    </div>
                  </span>
                )}
                {showNewBadge(launchAt, lastActiveSeason, upcomingSeason) && (
                  <div className="rounded bg-rb-black py-[3px] px-1.5 text-xs font-semibold text-white">
                    New
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {expanded && (
        <CohortPreview
          program={program}
          timezone={timezone}
          season={season}
          isMoreInfoScreen={isMoreInfoScreen}
        />
      )}
    </div>
  )
}

SelectProgramCard.propTypes = {
  program: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  setSelectedProgram: PropTypes.func,
  expanded: PropTypes.bool,
  setExpandedProgram: PropTypes.func,
  containerId: PropTypes.string,
  timezone: PropTypes.string,
  season: PropTypes.object,
  upcomingSeason: PropTypes.object,
  lastActiveSeason: PropTypes.object,
  sourceFlow: PropTypes.string,
  subscriptionExpireDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isMoreInfoScreen: PropTypes.bool
}

export default SelectProgramCard
