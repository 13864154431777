import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { ColorOptions } from 'components/typography/TypographyColorOptions'

interface ChildrenProps {
  children: ReactNode
  className?: string
  color?: ColorOptions
  id?: string
}

const RfParagraphMedium = ({
  id,
  children,
  className,
  color = ColorOptions.gray400
}: ChildrenProps) => (
  <p
    id={id}
    className={twMerge(
      `m-0 p-0 text-${color}`,
      className, // allow color, margin and padding overrides
      'text-[14px] font-normal leading-[21px] sm:text-[16px] sm:leading-6'
    )}
  >
    {children}
  </p>
)

export default RfParagraphMedium
