import { SquarishAvatarImage } from 'components/SquarishAvatarImage'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { Host, ProgramSelectorHostPartsFragment } from 'gql'

const HostAndCollaboratorDetails = ({
  hosts,
  label,
  blurb
}: {
  hosts: ProgramSelectorHostPartsFragment[]
  label: string
  blurb: string
}) => {
  return (
    <div>
      <RfHeader2 className="mt-12 mb-0">{label}</RfHeader2>
      <RfParagraphSmall className="mt-4 mb-8">{blurb}</RfParagraphSmall>
      {hosts.map((host: Host) => (
        <div
          className="mt-6 flex h-fit w-full flex-col rounded-lg bg-rb-gray-20 p-5 xs:flex-row"
          key={host.id}
        >
          <SquarishAvatarImage
            avatarUrl={host.thumbnailUrl}
            alt={host.name || 'Host picture'}
            className="mb-5 min-h-[93px] min-w-[96px] rounded xs:mb-0"
          />
          <div className="xs:ml-8">
            <RfParagraphMediumBold className="mb-1">{host.name}</RfParagraphMediumBold>
            <RfParagraphMini>{host.bio}</RfParagraphMini>
          </div>
        </div>
      ))}
    </div>
  )
}

export default HostAndCollaboratorDetails
