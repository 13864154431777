import { Maybe, Season } from 'gql'

import { isAfterDate, isBeforeDate } from 'utils/date'

export const showNewBadge = (
  launchAt?: string,
  lastActiveSeason?: Maybe<Season>,
  upcomingSeason?: Maybe<Season>
) => {
  const lastActiveSeasonEnrollmentsClose = lastActiveSeason?.enrollmentsCloseAt
  const upcomingSeasonEnrollmentsClose = upcomingSeason?.enrollmentsCloseAt
  const launchAtDate = launchAt && new Date(launchAt)

  const isLaunchAfterLastActiveSeasonEnrollmentsClose =
    lastActiveSeasonEnrollmentsClose &&
    isAfterDate(new Date(lastActiveSeasonEnrollmentsClose), launchAtDate)
  const isLaunchBeforeUpcomingSeasonEnrollmentsClose =
    upcomingSeasonEnrollmentsClose &&
    isBeforeDate(new Date(upcomingSeasonEnrollmentsClose), launchAtDate)
  const isCurrentTimeBeforeUpcomingSeasonEnrollmentsClose =
    upcomingSeasonEnrollmentsClose &&
    isBeforeDate(new Date(upcomingSeasonEnrollmentsClose))

  return (
    launchAt &&
    isLaunchAfterLastActiveSeasonEnrollmentsClose &&
    (!upcomingSeason ||
      (isLaunchBeforeUpcomingSeasonEnrollmentsClose &&
        isCurrentTimeBeforeUpcomingSeasonEnrollmentsClose))
  )
}
