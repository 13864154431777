import { CohortEventTimes } from 'gql'

import { getDurationInWeeksText, getMeetingTimesInText } from 'utils/cohortUtils'

const SelectProgramCardTime = ({
  eventTimes,
  durationInWeeks,
  timezone
}: {
  eventTimes: CohortEventTimes[]
  durationInWeeks: number
  timezone?: string
}) => {
  if (!eventTimes && !durationInWeeks) return null

  return (
    <>
      <div className="mb-3 text-xs font-bold">
        {durationInWeeks && <>{getDurationInWeeksText(durationInWeeks)}</>}

        {durationInWeeks && eventTimes && (
          <>
            <span> &#8226; </span>
          </>
        )}

        {eventTimes && <span>meets {getMeetingTimesInText(eventTimes, timezone)}</span>}
      </div>
    </>
  )
}

export default SelectProgramCardTime
