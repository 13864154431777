import React, { KeyboardEvent, MouseEvent, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { cn } from 'utils/tailwind'

export type ButtonVariant = 'fill' | 'outline' | 'text-only' | 'artifacts-secondary'

export type ButtonColors = 'teal' | 'destructive' | 'default' | 'premium'

export type ButtonStyle = 'loadingSpinner' | 'disabled' | 'activeNav' | ButtonColors

export interface ButtonProps {
  'id'?: string
  'isActiveNav'?: boolean
  'capitalizeText'?: boolean
  'color'?: ButtonColors
  'children': ReactNode
  'className'?: string
  'data-for'?: string
  'data-tip'?: string
  'disabled'?: boolean
  'fullWidth'?: boolean
  'iconBefore'?: ReactNode
  'iconClassName'?: string
  'iconAfter'?: ReactNode
  'onClick'?: (e: React.MouseEvent | React.KeyboardEvent) => void
  'shape'?: 'rounded' | 'rounded-full' | 'rounded-none'
  'size'?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large'
  'style'?: React.CSSProperties
  'type'?: 'button' | 'submit' | 'reset'
  'variant'?: ButtonVariant
  'dataTest'?: string
  'form'?: string
  'isLoadingSpinner'?: boolean
  'href'?: string
  'target'?: '_blank' | '_self' | '_parent' | '_top'
  'tabIndex'?: number
}

const Component = ({
  href,
  type,
  children,
  ...props
}: ButtonProps & React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement>) => {
  if (!href) {
    return (
      <button type={type} {...props}>
        {children}
      </button>
    )
  }

  // If href begins with a URI scheme (mailto counts), use a standard <a> element
  return href.match(/^[a-zA-Z]+:/) ? (
    <a href={href} {...props}>
      {children}
    </a>
  ) : (
    <Link to={href} {...props}>
      {children}
    </Link>
  )
}

function getButtonStyle(
  color: ButtonColors,
  disabled?: boolean,
  isLoadingSpinner?: boolean,
  isActiveNav?: boolean
): ButtonStyle {
  if (isLoadingSpinner) return 'loadingSpinner'
  if (disabled) return 'disabled'
  if (isActiveNav) return 'activeNav'
  return color
}

const Button = ({
  id,
  type = 'button',
  variant = 'fill',
  color = 'default',
  size = 'large',
  shape = 'rounded',
  fullWidth = false,
  disabled = false,
  iconBefore,
  capitalizeText = false,
  iconAfter,
  iconClassName,
  className = '',
  style = {},
  onClick,
  children,
  'data-tip': dataTip,
  'data-for': dataFor,
  dataTest,
  form,
  isLoadingSpinner = false,
  href,
  target,
  isActiveNav,
  tabIndex = 0,
  ...rest
}: ButtonProps & React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement>) => {
  const buttonStyle = getButtonStyle(color, disabled, isLoadingSpinner, isActiveNav)
  const classes = [
    'flex items-center justify-center border transition duration-200 ease-in-out font-sans font-medium',
    href ? 'text-rb-gray-400 hover:text-rb-gray-400 hover:no-underline' : '',
    color === 'premium' ? getPremiumColorSizeClasses(size) : getSizeClasses(size),
    getColorClasses({
      variant,
      buttonStyle
    }),
    getShapeClasses(shape),
    fullWidth ? 'w-full' : 'w-max',
    className
  ]
    .filter((v) => v !== '')
    .join(' ')

  const handleOnClick = (
    e:
      | MouseEvent<HTMLButtonElement | HTMLAnchorElement>
      | KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    if (href && href[0] === '#') {
      e.preventDefault()

      const el = document.getElementById(href.substring(1))
      const newUrl = `${window.location.pathname}${href}`
      window.history.pushState(
        { ...window.history.state, as: newUrl, url: newUrl },
        '',
        newUrl
      )

      el?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }

    onClick?.(e)
  }

  return (
    <Component
      id={id}
      type={type}
      disabled={disabled || isLoadingSpinner || isActiveNav}
      data-tip={dataTip}
      onClick={disabled ? (e) => e.preventDefault() : handleOnClick}
      className={cn(classes, capitalizeText && 'capitalize')}
      style={style}
      data-test={dataTest}
      data-testid={dataTest}
      data-for={dataFor}
      form={form}
      href={href}
      target={target}
      tabIndex={disabled ? -1 : tabIndex}
      {...rest}
    >
      {iconBefore && (
        <span className={twMerge('mr-2.5 h-auto w-4 fill-current', iconClassName)}>
          {iconBefore}
        </span>
      )}
      {!isLoadingSpinner && children}
      {isLoadingSpinner && (
        <span
          className="spinner-border ml-[39px] mr-[39px] inline-block h-[16.5px] w-[16.5px] animate-spin rounded-full border-4 border-t-transparent"
          aria-label="Loading"
        ></span>
      )}
      {iconAfter && (
        <span className={twMerge('ml-2.5 h-auto w-4 fill-current', iconClassName)}>
          {iconAfter}
        </span>
      )}
    </Component>
  )
}

function getShapeClasses(shape: string) {
  if (shape === 'rounded') return 'rounded'
  if (shape === 'rounded-full') return 'rounded-full'
  if (shape === 'rounded-none') return 'rounded-none'
}

function getSizeClasses(size: string) {
  if (size === 'x-small') return 'px-4 py-1 text-sm'
  if (size === 'small') return 'px-6 py-3 text-sm'
  if (size === 'medium') return 'px-6 py-3 text-sm'
  if (size === 'large') return 'px-6 py-4 text-sm'
  if (size === 'x-large') return 'px-12 py-6 text-xl'
}

// The premium button color needs specific padding to account for the lack of a border.
// The border is removed becuase it doesn't play well with the background gradent.
// This is causing the button to be smaller than other versions, so placing them side-by-side would not align them properly.
// The solution is to increase the padding by 1px on each side.
function getPremiumColorSizeClasses(size: string) {
  if (size === 'x-small') return 'px-[17px] py-[5px] text-sm'
  if (size === 'small') return 'px-[25px] py-[13px] text-sm'
  if (size === 'medium') return 'px-[25px] py-[13px] text-sm'
  if (size === 'large') return 'px-[25px] py-[17px] text-sm'
  if (size === 'x-large') return 'px-[49px] py-[25px] text-xl'
}

interface GetColorClassesInput {
  variant: ButtonVariant
  buttonStyle: ButtonStyle
}

const getColorClasses = ({ variant, buttonStyle }: GetColorClassesInput) => {
  const variantClasses = {
    'fill': {
      loadingSpinner:
        'text-white bg-rb-black border-rb-black hover:bg-rb-black hover:border-rb-black cursor-default',
      disabled:
        'color-input-text bg-rb-gray-50 border-rb-gray-50 hover:bg-rb-gray-50 hover:border-rb-gray-50 fill-rb-gray-200 cursor-default text-rb-gray-200 hover:text-rb-gray-200',
      activeNav: '',
      default:
        'bg-rb-black border-rb-black text-white hover:bg-rb-teal-200 hover:border-rb-teal-200 hover:text-white active:bg-rb-teal-300 active:border-rb-teal-300',
      teal: 'bg-rb-teal-200 border-rb-teal-200 text-white hover:text-white hover:bg-rb-teal-300 hover:border-rb-teal-300',
      destructive:
        'text-white hover:text-white border-rb-destructive bg-rb-destructive hover:border-rb-warning hover:bg-rb-warning hover:border-rb-warning',
      premium:
        '!text-white border-none bg-premium-gradient hover:text-white hover:bg-none hover:bg-rb-pink-200 hover:transition-none'
    },
    'outline': {
      loadingSpinner: '',
      disabled:
        'bg-white border-rb-gray-100 text-rb-gray-200 hover:text-rb-gray-200 hover:border-rb-gray-100 cursor-default',
      activeNav: 'bg-rb-green-75 border-rb-black text-rb-black cursor-default',
      default:
        'bg-white border-rb-black text-rb-black hover:border-rb-teal-200 active:border-rb-teal-300 active:bg-rb-gray-50',
      teal: 'bg-transparent border-rb-teal-200 text-rb-teal-200 hover:border-rb-teal-400 hover:text-rb-teal-400 active:bg-rb-gray-50',
      destructive: '',
      premium: ''
    },
    'text-only': {
      loadingSpinner: '',
      disabled:
        'bg-transparent border-transparent text-rb-gray-200 hover:text-rb-gray-200 cursor-default',
      activeNav: '',
      default:
        'bg-transparent border-transparent text-rb-black hover:bg-rb-gray-50 active:bg-rb-gray-100',
      teal: '',
      destructive: '',
      premium: ''
    },
    'artifacts-secondary': {
      loadingSpinner: '',
      disabled: 'text-rb-gray-300',
      activeNav: '',
      default:
        'bg-white border-rb-black text-rb-black active:text-rb-teal-300 active:border-rb-teal-300 active:bg-rb-gray-50 hover:border-rb-teal-50 hover:bg-rb-teal-50 focus:border-rb-teal-50 focus:bg-rb-teal-50',
      teal: '',
      destructive: '',
      premium: ''
    }
  }[variant]

  return variantClasses?.[buttonStyle]
}

export default Button
