import { twMerge } from 'tailwind-merge'

import { getColorFromFullName } from 'utils/colors'

const DefaultAvatar = ({
  fullName,
  className,
  zIndex
}: {
  fullName: string
  className?: string
  zIndex?: number
}) => (
  <span
    style={{ zIndex }}
    className={twMerge(
      getColorFromFullName(fullName.trim()),
      'flex place-items-center items-center justify-center uppercase text-white',
      className
    )}
  >
    {fullName[0]}
  </span>
)

export default DefaultAvatar
