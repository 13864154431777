import React, { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { SVGIcon } from 'components/Icon'
import { IFormElementBase } from 'components/forms/formTypes'
import { ChevronDownThin } from 'components/icons'

import useOnClickOutside from 'hooks/useOnClickOutside'

import { onKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'

import withContainer from '../enhancers/withContainer'
import withDefaultEnhancers from '../enhancers/withDefaultEnhancers'
import withDescription from '../enhancers/withDescription'
import withLabel from '../enhancers/withLabel'
import withValidation from '../enhancers/withValidation'

const MULTI_SELECT_DELIM = ','

export interface SelectOption {
  id: string | number
  option: React.ReactNode
}

export interface SelectProps extends IFormElementBase {
  'options': SelectOption[]
  'setValue'?: (name: string, id: string | number, options: any) => void
  'getValues'?: (name: string) => void
  'watch'?: (name: string) => void
  'value'?: string | number | null
  'onChange'?: (id: string | number) => void
  'disabled'?: boolean
  'showLabel'?: boolean
  'data-test'?: string
  'classNameAdditions'?: string
  'dropdownClassName'?: string
  'iconClassName'?: string
  'isMultiSelect'?: boolean
  'labelClassNames'?: string
  'optionsClassName'?: string
}

export const Select = ({
  name,
  setValue,
  getValues,
  value,
  onBlur,
  onChange,
  watch,
  isMultiSelect = false,
  placeholder = 'Select item',
  disabled = false,
  options = [],
  classNameAdditions = '',
  dropdownClassName = '',
  iconClassName = '',
  optionsClassName = '',
  'data-test': dataTest
}: SelectProps) => {
  const [showList, setShowList] = useState(false)
  const testId = dataTest || name

  const val = getValues ? getValues(name) : value
  const selectedItem = val ? options.find((o) => o.id === val) : null
  if (isMultiSelect) watch?.(name)

  const optionContainerRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(optionContainerRef, () => {
    setShowList(false)

    // Manually trigger onBlur, since we are not using a native select element.
    onBlur?.({
      target: {
        value: selectedItem?.option
      }
    })
  })

  const handleSelectItem = (id: string | number) => {
    if (!isMultiSelect) {
      setValue?.(name, id, { shouldValidate: true })
      onChange?.(id)
      setShowList(false)
    } else {
      const selectedValues = val?.toString().split(MULTI_SELECT_DELIM) || []
      if (isSelectedWithMultiSelect(id)) {
        const newValue = selectedValues
          .filter((v) => v !== id.toString() && v !== '')
          .join(MULTI_SELECT_DELIM)
        setValue?.(name, newValue, { shouldValidate: true })
        onChange?.(newValue)
      } else {
        const newValue = `${val}${val ? MULTI_SELECT_DELIM : ''}${id}`
        setValue?.(name, newValue, { shouldValidate: true })
        onChange?.(newValue)
      }
    }
  }

  const isSelectedWithMultiSelect = (id: string | number) => {
    const selectedValues = val?.toString().split(MULTI_SELECT_DELIM) || []
    return selectedValues?.includes(id.toString())
  }

  const onSelectKeyUp = (e: React.KeyboardEvent) => {
    const { key } = e

    if (!showList && [' ', 'ArrowDown'].includes(key)) {
      setShowList(true)
    }
  }

  useEffect(() => {
    const containerEl = optionContainerRef.current

    if (showList && containerEl) {
      if (selectedItem) {
        const selectedOptionEl = containerEl.querySelector(
          `[data-id="${selectedItem.id}"]`
        )

        if (selectedOptionEl && selectedOptionEl instanceof HTMLElement) {
          selectedOptionEl.focus()
        }
      } else {
        const firstOption = containerEl.firstElementChild

        if (firstOption && firstOption instanceof HTMLElement) {
          firstOption.focus()
        }
      }
    }
  }, [showList, selectedItem])

  const onOptionKeyUp = (optionId: string | number) => (e: React.KeyboardEvent) => {
    const { key } = e

    if ([' ', 'Enter'].includes(key)) {
      handleSelectItem(optionId)
    } else if (showList && key === 'Escape') {
      setShowList(false)
    } else if (['ArrowUp', 'ArrowDown'].includes(key)) {
      const optionEl = e.currentTarget
      const siblingToFocus =
        key === 'ArrowUp' ? optionEl.previousElementSibling : optionEl.nextElementSibling

      if (siblingToFocus && siblingToFocus instanceof HTMLElement) {
        siblingToFocus.focus()
      }
    }
  }

  // Hide the dropdown when tabbing outside of the options elements
  const onOptionTabPressed = () => {
    setShowList(false)
  }
  return (
    <div className="relative">
      <div
        className={twMerge(
          'flex w-full items-center justify-between px-3 py-2',
          classNameAdditions
        )}
        onClick={() => {
          if (!disabled) {
            setShowList(!showList)
          }
        }}
        role="button"
        tabIndex={0}
        aria-haspopup="listbox"
        data-test={testId}
        onKeyUp={(e) => {
          if (!disabled) {
            onSelectKeyUp(e)
          }
        }}
      >
        {selectedItem ? (
          <span className="text-rb-gray-500">{selectedItem.option}</span>
        ) : (
          placeholder
        )}
        <ChevronDownThin
          className={twMerge('ml-1 h-4 w-4', iconClassName, showList && 'rotate-180')}
        />
      </div>

      {!!showList && (
        <div
          ref={optionContainerRef}
          className={twMerge(
            'max-w-screen absolute mt-2 w-full border border-rb-gray-100 bg-white py-2 outline-none sm:w-auto',
            dropdownClassName
          )}
          data-test={`${testId}-list`}
          style={{ zIndex: 9999 }}
        >
          {options.map((o) => {
            return (
              <div
                className={cn(
                  'mx-2 flex cursor-pointer px-4 py-2 leading-7 hover:bg-neutral-100',
                  optionsClassName
                )}
                key={o.id}
                onClick={() => handleSelectItem(o.id)}
                role="button"
                data-test={`${testId}-list-item`}
                tabIndex={-1}
                onKeyUp={onOptionKeyUp(o.id)}
                onKeyDown={onKeyPress('Tab', onOptionTabPressed)}
                data-id={o.id}
              >
                {isMultiSelect && (
                  <div className="flex w-6">
                    {isSelectedWithMultiSelect(o.id) && (
                      <SVGIcon name="icon-checkmark" height="8" width="11" />
                    )}
                  </div>
                )}
                {o.option}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export const SelectWithLabel = withLabel(Select)

export const SelectWithForm = ({
  name,
  options,
  label,
  value,
  onChange,
  placeholder,
  disabled,
  showLabel,
  isMultiSelect,
  'data-test': dataTest,
  labelClassNames,
  ...rest
}: SelectProps) => {
  const { register, errors, setValue, getValues, watch } = useFormContext()

  const hasError = errors && errors[name]
  const Element = labelClassNames
    ? withValidation(withLabel(withDescription(withContainer(Select)), labelClassNames))
    : withDefaultEnhancers(Select)

  return (
    <>
      <Element
        hasError={hasError}
        name={name}
        options={options}
        label={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        register={register}
        setValue={setValue}
        getValues={getValues}
        showLabel={showLabel}
        data-test={dataTest}
        isMultiSelect={isMultiSelect}
        watch={watch}
        {...rest}
      />
      {/* Actual input element is hidden. This is what submits the value to the form. */}
      <input
        style={{ display: 'none' }}
        id={name}
        name={name}
        type="text"
        ref={register}
      />
    </>
  )
}
