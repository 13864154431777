const BookmarkIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 12 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    data-testid="bookmark-icon"
  >
    <path d="M0.5 15V1H11.5V15L6 11L0.5 15Z" />
  </svg>
)

export default BookmarkIcon
