import { FAQ } from 'components/FAQs'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'

import { Faq, Season } from 'gql'

const FAQs = ({ season }: { season: Season }) => {
  return (
    <div className="mt-12 flex flex-col xs:flex-row xs:items-start">
      <RfParagraphMediumBold className="mr-6 w-full grow whitespace-pre-wrap xs:max-w-[100px]">
        Frequently asked questions
      </RfParagraphMediumBold>
      <div className="mt-8 flex grow flex-col md:mt-0">
        {season.faqs &&
          season.faqs.map((faq: Faq) => (
            <div key={`faq_${faq.questionNumber}`} className="flex flex-col xs:ml-5">
              <FAQ question={faq.question} answer={faq.answer} />
            </div>
          ))}
      </div>
    </div>
  )
}

export default FAQs
