export const setSearchParam = (param: string, value: string) => {
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.set(param, value)
  window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`)
}
export const deleteSearchParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.delete(param)
  window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`)
}
