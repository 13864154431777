import {
  any,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string
} from 'prop-types'

// Defaults
export const typeAny = any
export const typeString = string
export const typeNum = number
export const typeStringNumUnion = string | number
export const typeShape = shape
export const typeOneOfType = oneOfType
export const typeOneOf = oneOf
export const typeArrayOf = arrayOf
export const typeBool = bool
export const typeFunc = func
export const typeId = oneOfType([string, number])

// Component specific
export const typeStyle = shape()
export const typeChildren = oneOfType([arrayOf(node), node, func, any])
export const typeRef = typeOneOfType([typeFunc, object])

// Data - Can split these into individual files if they're getting too long.

export const typeUser = typeShape({
  id: typeString | typeNum
})

export const typeEvent = typeShape({
  id: typeStringNumUnion.isRequired,
  name: typeString,
  kind: typeString,
  attendingStatus: typeString,
  location: typeString,
  online: typeBool,
  description: typeString,
  past: typeBool,
  recording_url: typeString,
  uuid_url: typeString,
  day: typeString,
  time_of_day: typeString,
  starts_at: typeString,
  ends_at: typeString,
  time_until: typeString,
  googleCalendarStartsAt: typeString,
  googleCalendarEndsAt: typeString,
  cmsProgramId: typeId,
  cmsModuleId: typeNum,
  summaryWistiaCode: typeString,
  willBeRecorded: typeBool
})

export const typeEventSpeaker = typeShape({
  id: typeId.isRequired,
  kind: typeString,
  name: typeString,
  companyName: typeString,
  avatarUrl: typeString,
  user: typeUser
})

export const typeProgramApplication = typeShape({
  id: typeId.isRequired,
  source: typeString,
  programName: typeString,
  email: typeString,
  companyDomain: typeString,
  companyName: typeString,
  companySize: typeString,
  title: typeString,
  linkedinUrl: typeString,
  season: typeString
})

// json.katie image_url('katie.png')
// key is the person name, value is the image url.
export const typeStaticImages = object

export default typeString
