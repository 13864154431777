import { useEffect, useState } from 'react'

let openDropdown: ((newState: boolean) => void) | null = null

const useDropdown = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }

  // closes previously open dropdown, so we only have one active dropdown at a time
  useEffect(() => {
    if (isOpen) {
      // when a dropdown is open
      if (openDropdown) {
        // if we already have an open dropdown, we close it
        openDropdown(false)
      }

      openDropdown = setIsOpen // save the instance of the newly open dropdown (because we don't have access to the instance of the component, we save the setIsOpen function instance)
    } else {
      // when a dropdown closes, it can either be triggered by itself (clicking the meatball menu again), or by closing the already open dropdown on line 18
      if (openDropdown === setIsOpen) {
        // if it was closed by itself, we need to reset the instance
        openDropdown = null
      }
    }
  }, [isOpen])

  return { isOpen, toggleDropdown, setIsOpen }
}

export default useDropdown
