import * as React from 'react'
import { twMerge } from 'tailwind-merge'

export default function RoundedCheckmark(
  props: React.SVGAttributes<SVGElement>
): React.ReactElement {
  const { className, width = '20', height = '20', ...rest } = props

  return (
    <svg
      className={twMerge('text-rb-green-100', className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.088 1.7288C14.0323 1.77721 13.8969 1.92899 11.114 5.90659C8.33119 9.88419 6.01615 13.1787 5.96947 13.2277C5.65599 13.5567 5.05177 13.574 4.68547 13.2644C4.6254 13.2137 3.97334 12.3685 3.23641 11.3863C2.39315 10.2622 1.85839 9.57708 1.79359 9.53759C1.65436 9.4527 1.49977 9.45849 1.33555 9.55471C1.13635 9.67145 1.06665 9.78526 1.06665 9.99387V10.1673L2.47423 12.0439C3.96531 14.0317 4.00716 14.0799 4.42518 14.2893C4.98991 14.5721 5.67014 14.5639 6.22783 14.2674C6.66268 14.0362 6.48139 14.2856 11.9414 6.48438C14.8 2.40001 14.8637 2.32822 14.8749 2.15667C14.8886 1.9456 14.8443 1.85558 14.6666 1.73334C14.4781 1.60364 14.2342 1.60172 14.088 1.7288Z"
        fill="currentColor"
      />
    </svg>
  )
}
