const ArrowRightLong = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.6973 4.5L9.91797 0.720703L9.12695 1.5293L11.5352 3.9375L0.250001 3.9375L0.250001 5.0625L11.5352 5.0625L9.12695 7.4707L9.91797 8.2793L13.6973 4.5Z" />
  </svg>
)

export default ArrowRightLong
